import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
  label: string;
  description?: string;
  children?: ReactNode;
  additionalMetaTags?: boolean;
}

function Title({ label, description, children, additionalMetaTags }: TitleProps) {
  return (
    
    <div className='calculator-page'>
      
      <h1>{label}</h1>
      { additionalMetaTags && <Helmet>
        <meta name="description" content={description} />
        <meta name="og:title" content={"FIRE Guy - " + label} />
        <meta name="twitter:title" content={"FIRE Guy - " + label} />
        <meta name="twitter:description" content={description} />
        </Helmet>
      }     
      {description && <div className='input-wrapper description' style={{ fontSize: '0.8em', color: 'gray', marginTop: '0px', textAlign: 'center', paddingRight: '10px' }}>{description}</div>}
      <hr />
      {children}
    </div>
  );
}

export default Title;
