import React, { useState } from 'react';
import { subscribe } from '../../../components/EmailSubscription/EmailSubscriptionForm';
import { ContainerPage } from '../../ContainerPage/ContainerPage';
import Title from '../../Title/Title';

function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = async () => {
    try {
      // Add the email to your Mailchimp audience
      const response = await subscribe('', '', email);

      console.log('Successfully subscribed:', response);
      setSubscribed(true); // Update the state to show a confirmation message and hide input fields
    } catch (error) {
      console.error('Error subscribing:', error);
      // Handle errors here (e.g., display an error message to the user)
    }
  };

  return (
    <div className='home-page'>
      <div className='calculators-page'>
        <Title label='Learn the art of FIRE 🔥' description={"Stay up-to-date with the latest personal finance strategies, investment insights, and market trends by subscribing to our newsletter!"} additionalMetaTags={true}>
          <div className='newsletter-content'>
            <p>Stay up-to-date with the latest personal finance strategies, investment insights, and market trends.</p>
            <h3>What You'll Get</h3>
            <ul>
              <li>Newsletters delivered to your inbox</li>
              <li>Expert analysis and commentary</li>
              <li>New Post notifications</li>
              <li>Exclusive offers and promotions</li>
            </ul>
            <p>Join tons of subscribers who trust our newsletter for their financial success.</p>
            {subscribed ? (
              <p>Thank you for subscribing!</p>
            ) : (
              <div className='subscription-form'>
                <input
                  type='email'
                  className='custom-input'
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className='custom-button' onClick={handleSubscribe}>
                  Subscribe
                </button>
              </div>
            )}
          </div>
        </Title>
      </div>
    </div>
  );
}

export default NewsletterSignup;
