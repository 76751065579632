import React, { useEffect, useState } from 'react';
import Title from '../../Title/Title';
import LabeledInput from '../../LabeledInput/LabeledInput';
import Dropdown, { debtOptions } from '../../Dropdowns/Dropdown';
import LabeledButton from '../../LabeledButton/LabeledButton';
import { Line } from 'react-chartjs-2';
import { formatUSD } from '../../../types/calculator';
import Table from '../../Table/Table';

// Define the custom CSS
const debtDetailsStyle: React.CSSProperties = {
  borderTop: '1px solid #ccc',
  paddingTop: '20px',
  marginTop: '20px',
};

const debtListStyle: React.CSSProperties = {
  listStyleType: 'none', // Remove bullet points from the list
  padding: 0, // Remove default padding
};

const debtItemStyle: React.CSSProperties = {
  marginBottom: '10px',
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: '#f8f8f8',
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.1)',
};

const debtDeleteButtonStyle: React.CSSProperties = {
  float: 'right',
  transform: 'translateY(65%)',
};

const colors = [
  'rgb(75, 192, 120)',
  'rgb(255, 99, 132, 1)',
  'rgb(75, 192, 192, 1)',
  'rgb(54, 162, 235, 1)',
  'rgb(255, 206, 86, 1)',
  'rgb(153, 102, 255, 1)',
  'rgb(255, 159, 64, 1)',
  'rgb(99, 176, 134)',
  'rgb(112, 191, 103)',
  'rgb(91, 181, 118)',
];

const background_colors = [
  'rgba(75, 192, 120)',
  'rgba(255, 99, 132, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(99, 176, 134)',
  'rgba(112, 191, 103)',
  'rgba(91, 181, 118)',
];

interface Debt {
  amount: number;
  interestRate: number;
  minPayment: number;
  monthsToPayOff: number;
  debtType: number;
  balance: number[];
  totalPaidOff: boolean;
}

function DebtSnowballCalculator() {
  const [debts, setDebts] = useState<Debt[]>([]);
  const [debtType, setDebtType] = useState(1);
  const [extraPayment, setExtraPayment] = useState(0);
  const [totalDebt, setTotalDebt] = useState(1000);
  const [totalMinPayment, setTotalMinPayment] = useState(50);
  const [interestRate, setInterestRate] = useState(4);
  const [chartData, setChartData] = useState<any>({});
  const [monthsToPayOff, setMonthsToPayOff] = useState(0);

  const calculatePayoffDate = (numberMonths: number) => {
    if (numberMonths >= 240) {
      return 'Never';
    }

    let years = Math.floor(numberMonths / 12);
    let months = numberMonths % 12;

    // Get current date
    let today = new Date();

    // Add the months to the current date
    let newDate = new Date(today.setMonth(today.getMonth() + months));

    // Add the years to the new date
    newDate = new Date(newDate.setFullYear(newDate.getFullYear() + years));

    return newDate.toLocaleDateString();
  };

  const isAllPaidOff = (updatedDebts: Debt[]) => {
    for (let i = 0; i < updatedDebts.length; i++) {
      if (updatedDebts[i].totalPaidOff === false) {
        return false;
      }
    }
    return true;
  };

  function setMinPaymentAdditionalPayment(debts: Debt[]) {
    let index = -1;
    let minPayment = 0;

    for (let i = 0; i < debts.length; i++) {
      if (debts[i].totalPaidOff === false || debts[i].totalPaidOff === undefined) {
        if (index === -1 || debts[i].minPayment < minPayment) {
          index = i;
          minPayment = debts[i].minPayment;
        }
      }
    }

    return index;
  }

  function handleDeleteDebt(index: number) {
    const updatedDebts = debts.filter((debt, debtIndex) => debtIndex !== index);
    setDebts(updatedDebts);
    calculatePayments(updatedDebts);
  }

  const calculatePayments = (updatedDebts: Debt[]) => {
    let additionalPayment = extraPayment;
    let curAddPayIdx = 0;
    let overPayment = 0;
    let maxMonths = 240;
    let curMonths = 1;

    const data = {
      labels: [] as string[],
      datasets: [] as {
        label: string;
        data: any[];
        currentPayment: number[];
        fill: boolean;
        borderColor: string;
        backgroundColor: string;
        tension: number;
        interestRate: any;
      }[],
    };

    for (let i = 0; i < updatedDebts.length; i++) {
      const selectedDebtOption = debtOptions.find((option) => option.value === updatedDebts[i].debtType);
      const debtTypeLabel = selectedDebtOption ? selectedDebtOption.label : 'Unknown';

      updatedDebts[i].totalPaidOff = false;

      data.datasets.push({
        label: 'Debt ' + (i + 1) + ' (' + debtTypeLabel + ')',
        data: ([] = [updatedDebts[i].amount]),
        currentPayment: ([] = [updatedDebts[i].minPayment]),
        fill: false,
        borderColor: colors[i % 10],
        backgroundColor: background_colors[i % 10],
        tension: 0.1,
        interestRate: updatedDebts[i].interestRate,
      });
    }

    curAddPayIdx = setMinPaymentAdditionalPayment(updatedDebts);

    while (isAllPaidOff(updatedDebts) === false && curMonths < maxMonths) {
      data.labels.push('Month ' + curMonths);

      for (let i = 0; i < updatedDebts.length; i++) {
        if (updatedDebts[i].totalPaidOff === true) {
          continue;
        }
        const monthlyInterest = updatedDebts[i].interestRate / 12 / 100;
        const monthlyPayment = updatedDebts[i].minPayment;

        // Add the interest and subtract the payment from the last months balance
        let previousBalance = data.datasets[i].data[curMonths - 1] || 0; // Use 0 if previous balance is not defined

        let subtractedPayment = 0;

        // If we are on the first debt, subtract the last debt's payment
        if (i === 0) {
          subtractedPayment = updatedDebts[updatedDebts.length - 1].minPayment;
        } else {
          subtractedPayment = updatedDebts[i - 1].minPayment;
        }

        let nextPayment =
          monthlyPayment +
          (curAddPayIdx === i ? additionalPayment : 0) +
          (overPayment !== 0 ? overPayment - subtractedPayment : 0);

        let newBalance = previousBalance * (1 + monthlyInterest) - nextPayment;

        overPayment = 0;

        if (newBalance < 0) {
          // Get the overpayment amount
          overPayment = parseFloat(Math.abs(newBalance).toFixed(2));

          // Set the new balance to 0
          newBalance = 0;
          data.datasets[i].currentPayment.push(nextPayment - overPayment);
        } else {
          data.datasets[i].currentPayment.push(overPayment + nextPayment);
        }

        data.datasets[i].data.push(newBalance);

        if (updatedDebts[i].totalPaidOff === false && newBalance <= 0) {
          additionalPayment += updatedDebts[i].minPayment;

          updatedDebts[i].totalPaidOff = true;
          updatedDebts[i].monthsToPayOff = curMonths;
          curAddPayIdx = setMinPaymentAdditionalPayment(updatedDebts);
        }
      }
      curMonths++;
    }
    data.labels.push('Month ' + curMonths);
    setMonthsToPayOff(curMonths);
    setChartData(data);
    //setDebts(updatedDebts.sort((debtA, debtB) => debtA.minPayment - debtB.minPayment));
    setDebts(updatedDebts);
  };

  const handleExtraPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraPayment(Number(e.target.value));
  };

  const handleTotalDebtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let totalDebtLocal = Number(e.target.value);
    setTotalDebt(totalDebtLocal);
    if (totalMinPayment > totalDebtLocal) {
      setTotalMinPayment(totalDebtLocal);
    }
  };

  const handleTotalMinPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let totalMinPayment = Number(e.target.value);
    if (totalMinPayment > totalDebt) {
      setTotalMinPayment(totalDebt);
    } else {
      setTotalMinPayment(totalMinPayment);
    }
  };

  const handleInterestRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInterestRate(Number(e.target.value));
  };

  function handleDebtTypeChange(option: number) {
    setDebtType(option);
  }

  const addNewDebt = () => {
    const newDebt = {
      amount: totalDebt,
      interestRate: interestRate,
      minPayment: totalMinPayment,
      monthsToPayOff: 0,
      debtType: debtType,
      balance: [totalDebt],
      totalPaidOff: false,
    };

    const newDebtArray = [...debts, newDebt];

    calculatePayments(newDebtArray); // Calculate payments right after adding the new debt
  };

  return (
    <Title label='Debt Snowball Calculator' description='This Debt Snowball Calculator is a powerful tool that helps you efficiently manage and pay
     off your debts. With this calculator, you can easily track your outstanding balances, interest rates, and minimum payments. By inputting this
      information, the calculator generates a personalized payment plan that prioritizes your debts based on the snowball method. This method focuses
       on paying off the smallest debts first, providing you with a sense of accomplishment and motivation to tackle larger debts. By following the
        calculated payment plan, you can accelerate your debt repayment journey and achieve financial freedom sooner. Start using the Debt Snowball
         Calculator today and take control of your debts with ease.' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-4'>
          <LabeledInput
            type='number'
            format='USD'
            label='Balance'
            min={'0'}
            step='0.01'
            desc='Current remaining balance on the debt'
            value={totalDebt}
            onChange={handleTotalDebtChange}
          />
          <LabeledInput
            type='number'
            format='%'
            label='Interest Rate'
            min={'0'}
            step='0.01'
            desc='The annual interest rate on the debt'
            value={interestRate}
            onChange={handleInterestRateChange}
          />
          <LabeledInput
            type='number'
            format='USD'
            label='Minimum Payment'
            min={'0'}
            step='0.01'
            desc='The minimum monthly payment on the debt, the lowest will be attempted to be paid off first'
            value={totalMinPayment}
            onChange={handleTotalMinPaymentChange}
          />
          <Dropdown
            label='Debt Type'
            desc='Type of debt, like a credit card, car loan, or personal loan'
            dropdown_type='debt'
            selected={debtType}
            onChange={handleDebtTypeChange}
          />
          <LabeledButton
            label='Add:'
            desc='Using the above fields, you can add your current outstanding debt to the calculator'
            buttonLabel='Add Debt'
            onClick={addNewDebt}
          />
          <LabeledInput
            type='number'
            format='USD'
            label='Extra Payment'
            desc='Any extra payment you can make on the debt, this will be applied to the lowest minimum payment first'
            min={'0'}
            step='0.01'
            value={extraPayment}
            onChange={handleExtraPaymentChange}
          />
        </div>
        <div className='col-lg-8'>
          {Object.keys(chartData).length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <div className='chart-size'>
                <Line
                  data={chartData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: (label: any) => `${formatUSD(label)}`,
                        },
                      },
                    },
                    elements: {
                      point: {
                        hitRadius: 25,
                      },
                    },
                    plugins: {
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function (context: any) {
                            const label = context.dataset.label;
                            const value = context.parsed.y;
                            const curPay = context.dataset.currentPayment[context.dataIndex];
                            const rate = context.dataset.interestRate;

                            const totalLabel = `${label} (${rate}%): ${formatUSD(value)}, Paid: ${formatUSD(curPay)}`;
                            return totalLabel;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={debtDetailsStyle}>
        <h2>Payoff Date: {calculatePayoffDate(monthsToPayOff)}</h2>
      </div>
      <div style={debtDetailsStyle}>
        <h2>Debt Details:</h2>
        <ul style={debtListStyle}>
          {debts.map((debt, index) => (
            <li key={index} style={debtItemStyle}>
              <div className='row'>
                <div className='col-lg-10'>
                  <strong>Debt #{index + 1}</strong>
                  <br />
                  <span>Amount: {formatUSD(debt.amount)}</span>
                  <br />
                  <span>Monthly Payment: {formatUSD(debt.minPayment)}</span>
                  <br />
                  <span>Months to Pay Off: {debt.monthsToPayOff}</span>
                </div>
                <div className='col-lg-2'>
                  <button
                    className='btn btn-danger'
                    style={debtDeleteButtonStyle}
                    onClick={() => handleDeleteDebt(index)}
                  >
                    X
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Table title={'Debt Snowball Breakdown'} data={chartData} />
    </Title>
  );
}

export default DebtSnowballCalculator;
