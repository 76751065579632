import DropdownBox from './DropdownBox';
import Label from '../Label/Label';

export const debtOptions = [
  { value: 1, label: 'Car Loan' },
  { value: 2, label: 'Student Loan' },
  { value: 3, label: 'Credit Card' },
  { value: 4, label: 'Other Non-Mortgage Debt' },
];

export const frequencyOptions = [
  { value: 1, label: 'Annually' },
  { value: 2, label: 'Semi-Annually' },
  { value: 4, label: 'Quarterly' },
  { value: 12, label: 'Monthly' },
  { value: 24, label: 'Semi-Monthly' },
  { value: 26, label: 'Bi-Weekly' },
  { value: 52, label: 'Weekly' },
  { value: 260, label: 'Daily' },
  { value: 2080, label: 'Hourly' },
];

function Dropdown({
  label,
  desc,
  dropdown_type,
  selected,
  onChange,
}: {
  label: string;
  desc?: String;
  dropdown_type: string;
  selected: any;
  onChange: (option: any) => void;
}) {
  if (dropdown_type == 'frequency') {
    return (
      <div className='input-wrapper'>
        <Label label={label} desc={desc} />
        <DropdownBox options={frequencyOptions} selectedOption={selected} onChange={onChange} />
      </div>
    );
  } else if (dropdown_type == 'debt') {
    return (
      <div className='input-wrapper'>
        <Label label={label} desc={desc} />
        <DropdownBox options={debtOptions} selectedOption={selected} onChange={onChange} />
      </div>
    );
  } else {
    return <div />;
  }
}

export default Dropdown;
