import { useEffect, useState } from 'react';

function Label({ label, desc }: { label: string; desc?: String }) {
  return (
    <div className='input-wrapper'>
      <div className='row'>
        <label>
          <strong>{label}</strong>
        </label>
        <div className='description'>{desc}</div>
      </div>
    </div>
  );
}

export default Label;
