import { Fragment } from 'react';
import { favoriteArticle, unfavoriteArticle } from '../../services/fire';
import { store } from '../../state/store';
import { useStore } from '../../state/storeHooks';
import { Post } from '../../types/post';
import { classObjectToClassName } from '../../types/style';
import { PostDataPreview } from '../PostDataPreview/PostDataPreview';
import { Pagination } from '../Pagination/Pagination';
import { PostViewerState /*endSubmittingFavorite, startSubmittingFavorite */ } from './PostsViewer.slice';

export function PostsViewer({
  toggleClassName,
  tabs,
  selectedTab,
  onPageChange,
  onTabChange,
}: {
  toggleClassName: string;
  tabs: string[];
  selectedTab: string;
  onPageChange?: (index: number) => void;
  onTabChange?: (tab: string, slug: string) => void;
}) {
  const { posts, postsCount, currentPage } = useStore(({ postViewer }) => postViewer);

  return (
    <Fragment>
      <PostsTabSet {...{ tabs, selectedTab, toggleClassName, onTabChange }} />
      <PostList posts={posts} />
      <Pagination currentPage={currentPage} count={postsCount} itemsPerPage={10} onPageChange={onPageChange} />
    </Fragment>
  );
}

function PostsTabSet({
  tabs,
  toggleClassName,
  selectedTab,
  onTabChange,
}: {
  tabs: string[];
  toggleClassName: string;
  selectedTab: string;
  onTabChange?: (tab: string, slug: string) => void;
}) {
  return (
    <div className={toggleClassName}>
      <ul className='nav nav-pills outline-active'>
        {tabs.map((tab) => (
          <Tab key={tab} tab={tab} active={tab === selectedTab} onClick={() => onTabChange && onTabChange(tab, '')} />
        ))}
      </ul>
    </div>
  );
}

function Tab({ tab, active, onClick }: { tab: string; active: boolean; onClick: () => void }) {
  return (
    <li className='nav-item'>
      <a
        className={classObjectToClassName({ 'nav-link': true, active })}
        href='#'
        onClick={(ev) => {
          ev.preventDefault();
          onClick();
        }}
      >
        {tab}
      </a>
    </li>
  );
}

function PostList({ posts }: { posts: PostViewerState['posts'] }) {
  return posts.match({
    none: () => (
      <div className='article-preview' key={1}>
        Loading articles...
      </div>
    ),
    some: (posts) => (
      <Fragment>
        {posts.length === 0 && (
          <div className='article-preview' key={1}>
            No articles are here... yet.
          </div>
        )}
        {posts.map(({ post_data, isSubmitting }, index) => (
          <PostDataPreview
            key={post_data.slug}
            post_data={post_data}
            isSubmitting={isSubmitting}
            //onFavoriteToggle={isSubmitting ? undefined : onFavoriteToggle(index, post)}
          />
        ))}
      </Fragment>
    ),
  });
}

/*function onFavoriteToggle(index: number, { data.slug, favorited }: Post) {
  return;
  /*return async () => {
    if (store.getState().app.user.isNone()) {
      location.hash = '#/login';
      return;
    }
    store.dispatch(startSubmittingFavorite(index));

    const article = await (favorited ? unfavoriteArticle(slug) : favoriteArticle(slug));
    store.dispatch(endSubmittingFavorite({ index, article }));
  };
}*/
