import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import LabeledInput from '../../LabeledInput/LabeledInput';
import Title from '../../Title/Title';
import { formatUSD } from '../../../types/calculator';
import LabeledInputRange from '../../LabeledInput/LabeledInputRange';
import Table from '../../Table/Table';

const InflationIncomeCalculator = () => {
  const [currentAnnualIncome, setCurrentAnnualIncome] = useState<number>(50000);
  const [currentAnnualIncomeGrowth, setCurrentAnnualIncomeGrowth] = useState<number>(3);
  const [currentAnnualSavings, setCurrentAnnualSavings] = useState<number>(10000);
  const [currentAnnualExpenses, setCurrentAnnualExpenses] = useState<number>(40000);
  const [currentAnnualExpenseGrowth, setCurrentAnnualExpenseGrowth] = useState<number>(3);
  const [currentAnnualDifference, setCurrentAnnualDifference] = useState<number>(0);
  const [currentSavingsRate, setCurrentSavingsRate] = useState<number>(20);
  const [chartData, setChartData] = useState<any>({});

  const calculateRetirement = () => {
    const data_points_inc: any[] = [];
    const data_points_sav: any[] = [];
    const data_points_exp: any[] = [];
    const data_points_diff: any[] = [];
    const label_points: any[] = [];
    const data = {
      labels: label_points,
      datasets: [
        {
          label: 'Annual Income',
          data: data_points_inc,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
        {
          label: 'Annual Savings',
          data: data_points_sav,
          fill: false,
          borderColor: 'rgb(75, 192, 120)',
          backgroundColor: 'rgb(75, 192, 120)',
          tension: 0.1,
        },
        {
          label: 'Annual Expenses',
          data: data_points_exp,
          fill: false,
          borderColor: 'rgb(192, 75, 75)',
          backgroundColor: 'rgb(192, 75, 75)',
          tension: 0.1,
        },
        {
          label: 'Annual Difference',
          data: data_points_diff,
          fill: false,
          borderColor: 'rgb(75, 75, 192)',
          backgroundColor: 'rgb(75, 75, 192)',
          tension: 0.1,
        },
      ],
    };

    let savingsRate = currentSavingsRate / 100;
    let annualExpenses = currentAnnualExpenses;
    let annualSavings = currentAnnualSavings;
    let annualIncome = currentAnnualIncome;
    let annualIncomeGrowth = currentAnnualIncomeGrowth / 100;
    let annualExpenseGrowth = currentAnnualExpenseGrowth / 100;
    let annualDifference = currentAnnualDifference;

    for (let i = 0; i <= 40; i++) {
      if (i !== 0) {
        annualExpenses = annualExpenses * (1 + annualExpenseGrowth);
        annualIncome = annualIncome * (1 + annualIncomeGrowth);
        annualSavings = annualIncome - annualExpenses;
        annualDifference = annualSavings - data.datasets[1].data[i - 1];
      } else {
        annualSavings = annualIncome * savingsRate;
        annualDifference = 0;
      }

      data.labels.push(`Year ${i}`);
      data.datasets[0].data.push(parseFloat(annualIncome.toFixed(2)));
      data.datasets[1].data.push(parseFloat(annualSavings.toFixed(2)));
      data.datasets[2].data.push(parseFloat(annualExpenses.toFixed(2)));
      data.datasets[3].data.push(parseFloat(annualDifference.toFixed(2)));
    }

    setChartData(data);
  };

  const handleCurrentAnnualIncomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualIncome = parseFloat(event.target.value);
    if (isNaN(currentAnnualIncome)) {
      setCurrentAnnualIncome(0);
      return;
    }
    let annualSavings =
      currentAnnualIncome - currentAnnualExpenses > 0 ? currentAnnualIncome - currentAnnualExpenses : 0;

    setCurrentAnnualIncome(currentAnnualIncome);
    setCurrentAnnualSavings(annualSavings);
    if (currentAnnualIncome < currentAnnualExpenses) {
      setCurrentAnnualExpenses(currentAnnualIncome);
    }

    setCurrentSavingsRate(Math.round((annualSavings / currentAnnualIncome) * 100));
  };

  const handleCurrentAnnualIncomeGrowthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualIncomeGrowth = parseFloat(event.target.value);
    if (isNaN(currentAnnualIncomeGrowth)) {
      setCurrentAnnualIncomeGrowth(0);
      return;
    }
    setCurrentAnnualIncomeGrowth(currentAnnualIncomeGrowth);
  };

  const handleCurrentAnnualSavingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualSavings = parseFloat(event.target.value);
    if (isNaN(currentAnnualSavings)) {
      setCurrentAnnualSavings(0);
      return;
    }
    setCurrentAnnualSavings(currentAnnualSavings);
    setCurrentAnnualExpenses(currentAnnualIncome - currentAnnualSavings);
    setCurrentSavingsRate((currentAnnualSavings / currentAnnualIncome) * 100);
  };

  const handleCurrentAnnualExpensesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualExpenses = parseFloat(event.target.value);
    if (isNaN(currentAnnualExpenses)) {
      setCurrentAnnualExpenses(0);
      return;
    }
    setCurrentAnnualExpenses(currentAnnualExpenses);
    setCurrentAnnualSavings(currentAnnualIncome - currentAnnualExpenses);
    setCurrentSavingsRate((currentAnnualSavings / currentAnnualIncome) * 100);
  };

  const handleAnnualExpensesGrowthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let annualExpenseGrowth = parseFloat(event.target.value);
    if (isNaN(annualExpenseGrowth)) {
      setCurrentAnnualExpenseGrowth(0);
      return;
    }
    setCurrentAnnualExpenseGrowth(annualExpenseGrowth);
  };
  const handleCurrentSavingsRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentSavingsRate = parseFloat(event.target.value);
    if (isNaN(currentSavingsRate)) {
      setCurrentSavingsRate(0);
      return;
    }
    let newAnnualSavings = Math.round((currentSavingsRate / 100) * currentAnnualIncome);
    setCurrentSavingsRate(currentSavingsRate);
    setCurrentAnnualSavings(newAnnualSavings);
    setCurrentAnnualExpenses(currentAnnualIncome - newAnnualSavings);
    handleCurrentAnnualDifferenceChange(event);
  };

  const handleCurrentAnnualDifferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualDifference = parseFloat(event.target.value);
    if (isNaN(currentAnnualDifference)) {
      setCurrentAnnualDifference(0);
      return;
    }
    setCurrentAnnualDifference(currentAnnualDifference);
  };

  const convertToFormat = () => {};

  useEffect(() => {
    calculateRetirement();
    convertToFormat();
  }, [
    currentAnnualIncome,
    currentAnnualIncomeGrowth,
    currentAnnualExpenses,
    currentAnnualExpenseGrowth,
    currentAnnualSavings,
    currentSavingsRate,
    currentAnnualDifference,
  ]);

  return (
    <Title label="Inflation's Effect on Income & Expenses Calculator" description='Calculate the impact of inflation on your income and
     expenses with this powerful calculator. Plan for the future by estimating your annual income growth and tracking changes in your expenses. 
      Stay on top of your financial goals and make informed decisions to achieve financial independence. Start using the Inflations Effect on Income & Expenses Calculator now!' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-4'>
          <br />
          <br />
          <LabeledInput
            label={'Annual Income'}
            desc={'The current annual income you earn'}
            type='number'
            value={currentAnnualIncome}
            format='USD'
            onChange={handleCurrentAnnualIncomeChange}
          />
          <LabeledInput
            label='Annual Income Growth'
            desc={'The expected annual income growth (salary increases)'}
            type='number'
            value={currentAnnualIncomeGrowth}
            format='%'
            onChange={handleCurrentAnnualIncomeGrowthChange}
          />
          <LabeledInput
            label='Annual Expenses'
            desc={'The amount of money that you spend every year'}
            type='number'
            value={currentAnnualExpenses}
            format='USD'
            onChange={handleCurrentAnnualExpensesChange}
          />
          <LabeledInput
            label='Annual Inflation Growth'
            desc={'The expected annual expense growth due to inflation'}
            type='number'
            value={currentAnnualExpenseGrowth}
            format='%'
            onChange={handleAnnualExpensesGrowthChange}
          />
          <LabeledInput
            label='Annual Savings'
            desc={'The amount of money that you save every year'}
            type='number'
            value={currentAnnualSavings}
            format='USD'
            onChange={handleCurrentAnnualSavingsChange}
          />
          <LabeledInputRange
            label='Savings Rate'
            desc={'The percentage of your income you save each year'}
            type='range'
            min='0'
            max='100'
            value={currentSavingsRate}
            onChange={handleCurrentSavingsRateChange}
          />
          <div className='row'>
            <div className='col-lg-10'></div>
            <h5>{currentSavingsRate.toFixed()}%</h5>
          </div>
          <br />
        </div>

        <div className='col-lg-8'>
          {Object.keys(chartData).length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <div className='chart-size'>
                <Line
                  data={chartData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: (label: any) => `${formatUSD(label)}`,
                        },
                      },
                    },
                    elements: {
                      point: {
                        hitRadius: 25,
                      },
                    },
                    plugins: {
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function (context: any) {
                            const label = context.dataset.label;
                            const value = context.parsed.y;

                            const totalLabel = `${label}: ${formatUSD(value)}`;
                            return totalLabel;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Table title={"Inflation's Effect on Income & Expenses"} data={chartData} />
    </Title>
  );
};

export default InflationIncomeCalculator;
