import { ContainerPage } from '../../ContainerPage/ContainerPage';
import { calcMap, calcTypes } from '../../../types/calculator';
import { Fragment } from 'react';
import Title from '../../Title/Title';
import { Link } from 'react-router-dom';
import Card from '../About/Card';

export function Calculators() {
  return (
    <div className='home-page'>
      <div className='calculators-page'>
        <Title label='Financial Calculators' description={"Calculate your investments, loans, retirement plans, and more with our comprehensive suite of financial calculators. Plan your financial future to achieve financial freedom!"} 
                additionalMetaTags={true}>
          <br />
          <div className='calculator-container'>
            <div className='calculator-column'>
              <div className='card-container-left'>
                <Card>
                  <h3>Investments</h3>
                  <hr />
                  {calculatorLinks(calcTypes.INVESTMENT)}
                </Card>
              </div>
              <div className='card-container-left'>
                <Card>
                  <h3>Loans</h3>
                  <hr />
                  {calculatorLinks(calcTypes.LOANS)}
                </Card>
              </div>
            </div>
            <div className='calculator-column'>
              <div className='card-container-right'>
                <Card>
                  <h3>Retirement</h3>
                  <hr />
                  {calculatorLinks(calcTypes.RETIREMENT)}
                </Card>
              </div>
              <div className='card-container-right'>
                <Card>
                  <h3>Miscellaneous</h3>
                  <hr />
                  {calculatorLinks(calcTypes.MISCELLANEOUS)}
                </Card>
              </div>
            </div>
          </div>
        </Title>
      </div>
    </div>
  );
}

function calculatorLinks(calcType: string) {
  const calc = calcMap[calcType];
  return (
    <Fragment>
      {Object.entries(calc).map(([key, value]) => (
        <div className='calculator-link' key={key}>
          <Link href={`calculator/${value}`} to={`calculator/${value}`}>
            {key}
          </Link>
        </div>
      ))}
    </Fragment>
  );
}
