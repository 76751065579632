import { Option } from '@hqoss/monads';
import { getPosts, getTags } from '../../../services/fire';
import { store } from '../../../state/store';
import { useStoreWithInitializer } from '../../../state/storeHooks';
import { PostsViewer } from '../../PostsViewer/PostsViewer';
import { PostViewerState, changePage, loadPosts, startLoadingPosts } from '../../PostsViewer/PostsViewer.slice';
import { ContainerPage } from '../../ContainerPage/ContainerPage';
import { changeTab, loadTags, startLoadingTags } from './Home.slice';
import { Posts, Tag, PostsFilters } from '../../../types/post';
import EmailSubscriptionForm from '../../EmailSubscription/EmailSubscriptionForm';
import { Helmet } from 'react-helmet';

export function Home() {
  const { tags, selectedTab } = useStoreWithInitializer(({ home }) => home, load);

  return (
    <div className='home-page'>
      <Helmet>
        <meta property="og:title" content="FIRE Guy - Financial Independence, Retire Early" />
        <meta
          property="og:description"
          content="Financial Independence for everyone. Learn, share, and calculate everything you need to become financially independent and secure."
        />
        <meta name="twitter:title" content="FIRE GUY - Financial Independence, Retire Early" />
        <meta
          name="twitter:description"
          content="FIRE Guy - Financial Independence for everyone. Learn, share, and calculate everything you need to become financially independent and secure."
        />
    </Helmet>
      <ContainerPage>
        <div className='col-md-9'>
          <PostsViewer
            toggleClassName='feed-toggle'
            selectedTab={selectedTab}
            tabs={buildTabsNames(selectedTab)}
            onPageChange={onPageChange}
            onTabChange={onTabChange}
          />
        </div>

        <div className='col-md-3'>
          <HomeSidebar tags={tags} />
        </div>
      </ContainerPage>
    </div>
  );
}

async function load() {
  store.dispatch(startLoadingPosts());
  store.dispatch(startLoadingTags());

  if (store.getState().app.user.isSome()) {
    store.dispatch(changeTab({ tab: 'Your Feed', slug: '' }));
  }

  const post: Posts = await getFeedOrGlobalArticles().then();
  store.dispatch(loadPosts(post));

  const tagsResult = await getTags();
  store.dispatch(loadTags(tagsResult));
}

function buildTabsNames(selectedTab: string) {
  const { user } = store.getState().app;

  return Array.from(new Set([...(user.isSome() ? ['Your Feed'] : []), 'Global Feed', selectedTab]));
}

async function onPageChange(index: number) {
  store.dispatch(changePage(index));

  const post: Posts = await getFeedOrGlobalArticles(index).then();
  store.dispatch(loadPosts(post));
}

// We may need to address index here as well
async function onTabChange(tab: string, slug: string) {
  store.dispatch(changeTab({ tab, slug }));
  store.dispatch(startLoadingPosts());

  const post: Posts = await getFeedOrGlobalArticles().then();
  store.dispatch(loadPosts(post));
}

async function getFeedOrGlobalArticles(index: number = 1) {
  const { selectedTab, selectedTagSlug } = store.getState().home;
  if (selectedTagSlug === '') {
    return getPosts({ page: index });
  }

  const filters: PostsFilters = {
    tag_slug: selectedTagSlug,
    page: index,
  };

  // We can add RSS feeds here to add to their experience. Eventually they can select preset RSS feeds to sub to.
  return getPosts(filters);
}

function HomeSidebar({ tags }: { tags: Option<Tag[]> }) {
  return (
    <div>
      <div className='sidebar'>
        <p>Popular Tags</p>

        {tags.match({
          none: () => <span>Loading tags...</span>,
          some: (tagArray) => {
            if (tagArray && Array.isArray(tagArray)) {
              return (
                <div className='tag-list'>
                  {tagArray.map((tag) => (
                    <a
                      key={tag.slug}
                      href='#'
                      className='tag-pill tag-default'
                      onClick={() => onTabChange(`# ${tag.name}`, tag.slug)}
                    >
                      {tag.name}
                    </a>
                  ))}
                </div>
              );
            } else {
              return <div>No tags found.</div>;
            }
          },
        })}
      </div>
      <br />
      <br />
      <EmailSubscriptionForm />
    </div>
  );
}
