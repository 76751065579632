import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getPost } from '../../../services/fire';
import { store } from '../../../state/store';
import { useStore } from '../../../state/storeHooks';
import { Data } from '../../../types/post';
import { initializePostPage, loadPost } from './PostPage.slice';
import { Helmet } from 'react-helmet';
import SocialMediaLinks from '../../Social/Socials';

/*
Add a line on the bottom to allow them to switch to next post or previous post
 */

export function PostPage() {
  const { slug } = useParams<{ slug: string }>();

  const {
    postPage: { post, commentSection },
    app: { user },
  } = useStore(({ postPage, app }) => ({
    postPage,
    app,
  }));

  useEffect(() => {
    onLoad(slug);
  }, [slug]);
  //<div className='article-actions'>OldMetaSection, here we can add next, prev post buttons</div> goes after <hr />
  return post.match({
    none: () => <div>Loading article...</div>,
    some: (post) => (
      <div className='article-page'>
        <div className='container page'>
          <PostPageBanner {...{ post }} />
          <hr />
          <div className='container page'>
            <div className='row article-content'>
              <div className='col-md-12' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.body) }} />
            </div>

            <hr />
            <PostPageBottomBanner {...{ post }} />
          </div>
        </div>
      </div>
    ),
  });
}

async function onLoad(slug: string) {
  store.dispatch(initializePostPage());
  const post = await getPost(slug);
  store.dispatch(loadPost(post));
}

function PostPageBanner(props: { post: Data }) {
  return (
    <div className='banner'>
        <Helmet>
        <meta property="og:title" content={props.post.title} />
        <meta
          property="og:description"
          content={props.post.summary}
        />
        <meta name="twitter:title" content={props.post.title} />
        <meta
          name="twitter:description"
          content={props.post.summary}
        />
        </Helmet>
      <div className='container'>
        <strong>
          <h1>{props.post.title}</h1>
        </strong>

        <PostMeta post={props.post} />
      </div>
    </div>
  );
}

function PostPageBottomBanner(props: { post: Data }) {
  return (
    <div className='banner'>
      <div className='container'>
        <PostBottomMeta post={props.post} />
      </div>
    </div>
  );
}

function PostMeta({ post }: { post: Data }) {
  return (
    <div className='article-meta'>
      <PostAuthorInfo post={post} />
    </div>
  );
}

function PostBottomMeta({ post }: { post: Data }) {
  return (
    <div className='article-meta'>
      <BottomAuthorInfo post={post} />
    </div>
  );
}

function PostAuthorInfo({
  post: {
    author: { first_name, last_name, profile_image, twitter_handle },
    published,
  },
}: {
  post: Data;
}) {
  return (
    <Fragment>
      <a href={`https://twitter.com/${twitter_handle}`} className='author'>
        <img src={profile_image || undefined} alt={`Author: ${first_name} ${last_name}`} />
      </a>
      <div className='info'>
        <a href={`https://twitter.com/${twitter_handle}`} className='author'>
          {first_name + ' ' + last_name}
        </a>
        <a href={`https://twitter.com/${twitter_handle}`} className='twitter-link'>
          @{twitter_handle}
        </a>
        <span className='date'>{format(published, 'PP')}</span>
      </div>
      <SocialMediaLinks />
      <div className='new-user'>
        <p>
          New here? You may want updates
          <Link to='/newsletter'> via email</Link>. Thanks for visiting!
        </p>
      </div>
    </Fragment>
  );
}

function BottomAuthorInfo({
  post: {
    author: { first_name, last_name, profile_image, twitter_handle },
    published,
  },
}: {
  post: Data;
}) {
  return (
    <Fragment>
      <div className='bottom-author-info'>
        <a href={`https://twitter.com/${twitter_handle}`} className='author'>
          <img src={profile_image || undefined} alt={`Author: ${first_name} ${last_name}`} />
        </a>
        <div className='info'>
          <a href={`https://twitter.com/${twitter_handle}`} className='author'>
            {first_name + ' ' + last_name}
          </a>
          <a href={`https://twitter.com/${twitter_handle}`} className='twitter-link'>
            @{twitter_handle}
          </a>
          <p>
            In 2023, the FIRE Guy started his journey to document his quest to grow his net worth and income to reach
            financial freedom. Obsessed with money and growth from a young age, he's managed to grow his net worth into
            the mid six figures range and has learned a thing or two about how to master your money. He provides the
            knowledge he has learned across his career and life to help you maximize your income and net worth, while
            keeping your spending in check. He's a big proponent of the FIRE movement and believes that anyone can
            achieve financial freedom if they are willing to put in the work and sacrifice. <br /> <br /> Get updates on
            new features and posts by subscribing to our <Link to='/newsletter'>Newsletter</Link> and{' '}
            <a href={`https://twitter.com/${twitter_handle}`}>joining the conversation on 𝕏!</a>
          </p>
          <SocialMediaLinks />
        </div>
      </div>
    </Fragment>
  );
}
