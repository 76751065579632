import { Err, Ok, Result } from '@hqoss/monads';
import axios from 'axios';
import { array, guard, object, string } from 'decoders';
import settings from '../config/settings';
import {
  Article,
  articleDecoder,
  ArticleForEditor,
  ArticlesFilters,
  FeedFilters,
  MultipleArticles,
  multipleArticlesDecoder,
} from '../types/article';
import { Posts, postsDecoder, postDecoder, Data, PostsFilters, ChangePost, Tag } from '../types/post';
import { Comment, commentDecoder } from '../types/comment';
import { GenericErrors, genericErrorsDecoder } from '../types/error';
import { objectToQueryString } from '../types/object';
import { Profile, profileDecoder } from '../types/profile';
import { User, userDecoder, UserForRegistration, UserSettings } from '../types/user';

const new_url = 'https://api.buttercms.com/v2/';
const post_endpoint = 'post/';
const old_url = settings.baseApiUrl;

export async function getArticles(filters: ArticlesFilters = {}): Promise<MultipleArticles> {
  const finalFilters: ArticlesFilters = {
    limit: 10,
    offset: 0,
    ...filters,
  };
  return guard(multipleArticlesDecoder)((await axios.get(`articles?${objectToQueryString(finalFilters)}`)).data);
}

export async function getPosts(filters: PostsFilters = {}): Promise<Posts> {
  const finalFilters: PostsFilters = {
    page_size: 10,
    ...filters,
  };

  const { data } = await axios.get(new_url + 'posts', {
    params: { auth_token: settings.butterApiKey, ...finalFilters },
  });
  const postsPayload = {
    posts: data,
  };

  // Fix nulls for decoder
  if (postsPayload['posts']['meta']['next_page'] === null) {
    postsPayload['posts']['meta']['next_page'] = '';
  }

  if (postsPayload['posts']['meta']['previous_page'] === null) {
    postsPayload['posts']['meta']['previous_page'] = '';
  }
  for (let i = 0; i < postsPayload['posts']['data'].length; i++) {
    if (postsPayload['posts']['data'][i]['featured_image'] === null) {
      postsPayload['posts']['data'][i]['featured_image'] = '';
    }
  }
  return guard(object({ posts: postsDecoder }))(postsPayload).posts;
}

export async function getTags(): Promise<Tag[]> {
  const response = await axios.get<{ data: Tag[] }>('https://api.buttercms.com/v2/tags', {
    params: { auth_token: settings.butterApiKey },
  });

  return response.data.data;
}

export async function login(email: string, password: string): Promise<Result<User, GenericErrors>> {
  try {
    const { data } = await axios.post(old_url + 'users/login', { user: { email, password } });

    return Ok(guard(object({ user: userDecoder }))(data).user);
  } catch (error: any) {
    const data: unknown = error.response?.data;
    return Err(guard(object({ errors: genericErrorsDecoder }))(data).errors);
  }
}

export async function getUser(): Promise<User> {
  const { data } = await axios.get(old_url + 'user');
  return guard(object({ user: userDecoder }))(data).user;
}

export async function favoritePost(slug: string): Promise<Posts> {
  return guard(object({ post: postsDecoder }))((await axios.post(old_url + `articles/${slug}/favorite`)).data).post;
}

export async function unfavoritePost(slug: string): Promise<Posts> {
  return guard(object({ post: postsDecoder }))((await axios.delete(old_url + `articles/${slug}/favorite`)).data).post;
}

export async function favoriteArticle(slug: string): Promise<Article> {
  return guard(object({ article: articleDecoder }))((await axios.post(old_url + `articles/${slug}/favorite`)).data)
    .article;
}

export async function unfavoriteArticle(slug: string): Promise<Article> {
  return guard(object({ article: articleDecoder }))((await axios.delete(old_url + `articles/${slug}/favorite`)).data)
    .article;
}

export async function updateSettings(user: UserSettings): Promise<Result<User, GenericErrors>> {
  try {
    const { data } = await axios.put(old_url + 'user', user);

    return Ok(guard(object({ user: userDecoder }))(data).user);
  } catch ({ data }) {
    return Err(guard(object({ errors: genericErrorsDecoder }))(data).errors);
  }
}

export async function signUp(user: UserForRegistration): Promise<Result<User, GenericErrors>> {
  try {
    const { data } = await axios.post(old_url + 'users', { user });

    return Ok(guard(object({ user: userDecoder }))(data).user);
  } catch ({ response: { data } }) {
    return Err(guard(object({ errors: genericErrorsDecoder }))(data).errors);
  }
}

export async function getPost(slug: string): Promise<Data> {
  const { data } = await axios.get(new_url + 'posts/' + slug + '/', { params: { auth_token: settings.butterApiKey } });

  const postPayload = {
    post: data,
  };

  const emptyChangePost: ChangePost = {
    slug: '',
    title: '',
    featured_image: '',
  };

  if (postPayload['post']['meta']['next_post'] === null) {
    postPayload['post']['meta']['next_post'] = emptyChangePost;
  }

  if (postPayload['post']['meta']['next_post']['featured_image'] === null) {
    postPayload['post']['meta']['next_post']['featured_image'] = '';
  }

  if (postPayload['post']['meta']['previous_post'] === null) {
    postPayload['post']['meta']['previous_post'] = emptyChangePost;
  }

  if (postPayload['post']['meta']['previous_post']['featured_image'] === null) {
    postPayload['post']['meta']['previous_post']['featured_image'] = '';
  }

  if (postPayload['post']['data']['featured_image'] === null) {
    postPayload['post']['data']['featured_image'] = '';
  }

  if (postPayload['post']['data']['url'] === null) {
    postPayload['post']['data']['url'] = '';
  }

  return guard(object({ post: postDecoder }))(postPayload).post.data;
}

export async function getProfile(username: string): Promise<Profile> {
  const { data } = await axios.get(old_url + `profiles/${username}`);
  return guard(object({ profile: profileDecoder }))(data).profile;
}

export async function followUser(username: string): Promise<Profile> {
  const { data } = await axios.post(old_url + `profiles/${username}/follow`);
  return guard(object({ profile: profileDecoder }))(data).profile;
}

export async function unfollowUser(username: string): Promise<Profile> {
  const { data } = await axios.delete(old_url + `profiles/${username}/follow`);
  return guard(object({ profile: profileDecoder }))(data).profile;
}

export async function getArticleComments(slug: string): Promise<Comment[]> {
  const { data } = await axios.get(old_url + `articles/${slug}/comments`);
  return guard(object({ comments: array(commentDecoder) }))(data).comments;
}

export async function getPostComments(slug: string): Promise<Comment[]> {
  const { data } = await axios.get(old_url + `articles/${slug}/comments`);
  return guard(object({ comments: array(commentDecoder) }))(data).comments;
}

export async function deleteComment(slug: string, commentId: number): Promise<void> {
  await axios.delete(old_url + `articles/${slug}/comments/${commentId}`);
}

export async function createComment(slug: string, body: string): Promise<Comment> {
  const { data } = await axios.post(old_url + `articles/${slug}/comments`, { comment: { body } });
  return guard(object({ comment: commentDecoder }))(data).comment;
}
