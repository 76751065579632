import axios from 'axios';
import { Fragment } from 'react';
import { BrowserRouter, HashRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { getUser } from '../../services/fire';
import { store } from '../../state/store';
import { useStoreWithInitializer } from '../../state/storeHooks';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Home } from '../Pages/Home/Home';
import { Calculators } from '../Pages/Calculators/Calculators';
import { Calculator } from '../Pages/Calculators/Calculator';
import { endLoad, loadUser } from './App.slice';
import { PostPage } from '../Pages/PostPage/PostPage';
import { Banner } from '../Banner/Banner';
//import AboutPage from '../Pages/About/AboutPage';
import NewsletterSignup from '../Pages/Newsletter/Newsletter';

export function App() {
  const { loading, user } = useStoreWithInitializer(({ app }) => app, load);

  const userIsLogged = user.isSome();
  /*
  These routes are not used anymore, but I keep them here for future reference. I will probably use them again in the future. They started below /education and about /post/:slug.
  <GuestOnlyRoute exact path='/login' userIsLogged={userIsLogged}>
              <Login />
            </GuestOnlyRoute>
            <GuestOnlyRoute exact path='/register' userIsLogged={userIsLogged}>
              <Register />
            </GuestOnlyRoute>
            <UserOnlyRoute exact path='/settings' userIsLogged={userIsLogged}>
              <Settings />
            </UserOnlyRoute>
             <Route path='/profile/:username'>
              <ProfilePage />
            </Route>
            */

  /*
                        <Route exact path='/education'>
              <Education />
            </Route>
            */

  return (
    <BrowserRouter>
      {!loading && (
        <Fragment>
          <Header />
          <Banner />
          <Switch>
            <Route path='/calculators' component={Calculators} />
            <Route path='/calculator/:slug' component={Calculator} />
            {/* <Route path='/about' component={AboutPage} /> */}
            <Route path='/newsletter' component={NewsletterSignup} />
            <Route path='/post/:slug' component={PostPage} />
            <Route exact path='/' component={Home} />
            <Redirect to='/' />
          </Switch>
          <Footer />
        </Fragment>
      )}
    </BrowserRouter>
  );
}

async function load() {
  const token = localStorage.getItem('token');
  if (!store.getState().app.loading || !token) {
    store.dispatch(endLoad());
    return;
  }
  axios.defaults.headers.Authorization = `Token ${token}`;

  try {
    store.dispatch(loadUser(await getUser()));
  } catch {
    store.dispatch(endLoad());
  }
}

/* istanbul ignore next */
function GuestOnlyRoute({
  children,
  userIsLogged,
  ...rest
}: { children: JSX.Element | JSX.Element[]; userIsLogged: boolean } & RouteProps) {
  return (
    <Route {...rest}>
      {children}
      {userIsLogged && <Redirect to='/' />}
    </Route>
  );
}

/* istanbul ignore next */
function UserOnlyRoute({
  children,
  userIsLogged,
  ...rest
}: { children: JSX.Element | JSX.Element[]; userIsLogged: boolean } & RouteProps) {
  return (
    <Route {...rest}>
      {children}
      {!userIsLogged && <Redirect to='/' />}
    </Route>
  );
}
