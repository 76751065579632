import Label from '../Label/Label';

function LabeledButton({
  label,
  desc,
  buttonLabel,
  onClick,
}: {
  label: string;
  desc?: String;
  buttonLabel: string;
  onClick: (option: any) => void;
}) {
  return (
    <div className='button-wrapper'>
      <Label label={label} desc={desc} />
      <div>
        <button onClick={onClick}>{buttonLabel}</button>
      </div>
    </div>
  );
}

export default LabeledButton;
