import React from 'react';
import { formatUSD } from '../../../types/calculator';

const SalaryTable = ({ data }: { data: any }) => {
  const { labels, datasets } = data;

  if (datasets === undefined) return <div></div>;

  const labelCells = datasets.map((dataset: any, index: number) =>
    Object.keys(dataset).map((label: string, labelIndex: number) => <th key={`${index}`}>{dataset.label}</th>)
  );

  const dataRows = labels.map((label: string, index: number) => (
    <tr key={index}>
      <td>{label}</td>
      {datasets.map((dataset: any, dataIndex: number) => (
        <td key={dataIndex}>{formatUSD(parseFloat(dataset[label.toLowerCase().replace('-', '')]))}</td>
      ))}
    </tr>
  ));

  return (
    <table className='custom-table'>
      <thead>
        <tr>
          <th></th>
          {labelCells.map((labelRow: JSX.Element[], index: number) => (
            <th key={index}>{labelRow[0]}</th>
          ))}
        </tr>
      </thead>

      <tbody>{dataRows}</tbody>
    </table>
  );
};

export default SalaryTable;
