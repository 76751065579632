import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import LabeledInput from '../../LabeledInput/LabeledInput';
import Title from '../../Title/Title';
import { formatUSD, formatUSDNoFormatting } from '../../../types/calculator';
import SalaryTable from './SalaryTable';
import Dropdown from '../../Dropdowns/Dropdown';

const SalaryCalculator = () => {
  const [amountPaid, setAmountPaid] = useState<number>(15.0);
  const [interval, setInterval] = useState<number>(2080);
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(40);
  const [daysPerWeek, setDaysPerWeek] = useState<number>(5);
  const [weeksPerYear, setWeeksPerYear] = useState<number>(52);
  const [unpaidHolidaysPerYear, setUnpaidHolidaysPerYear] = useState<number>(0);
  const [paidHolidaysPerYear, setPaidHolidaysPerYear] = useState<number>(10);
  const [unpaidVacationDaysPerYear, setUnpaidVacationDaysPerYear] = useState<number>(0);
  const [paidVacationDaysPerYear, setPaidVacationDaysPerYear] = useState<number>(10);
  const [hourlyRate, setHourlyRate] = useState<number>(15.0);
  const [unpaidAdjustedHourlyRate, setUnpaidAdjustedHourlyRate] = useState<number>(15.0);
  const [paidAdjustedHourlyRate, setPaidAdjustedHourlyRate] = useState<number>(15.0);
  const [totalAdjustedHourlyRate, setTotalAdjustedHourlyRate] = useState<number>(15.0);
  const [tableData, setTableData] = useState<any>({});

  const convertToHourlyRate = () => {
    return amountPaid / (weeksPerYear / interval) / hoursPerWeek;
  };

  const convertToUnpaidAdjustedHourlyRate = () => {
    const adjustedInterval =
      ((weeksPerYear * daysPerWeek - unpaidHolidaysPerYear - unpaidVacationDaysPerYear) /
        (weeksPerYear * daysPerWeek)) *
      interval;
    return amountPaid / (weeksPerYear / adjustedInterval) / hoursPerWeek;
  };

  const convertToPaidAdjustedHourlyRate = () => {
    const adjustedInterval =
      ((weeksPerYear * daysPerWeek + paidHolidaysPerYear + paidVacationDaysPerYear) / (weeksPerYear * daysPerWeek)) *
      interval;
    return amountPaid / (weeksPerYear / adjustedInterval) / hoursPerWeek;
  };

  const convertToTotalAdjustedHourlyRate = () => {
    const adjustedInterval =
      ((weeksPerYear * daysPerWeek +
        paidHolidaysPerYear +
        paidVacationDaysPerYear -
        unpaidHolidaysPerYear -
        unpaidVacationDaysPerYear) /
        (weeksPerYear * daysPerWeek)) *
      interval;
    return amountPaid / (weeksPerYear / adjustedInterval) / hoursPerWeek;
  };

  const calculateSalary = () => {
    const hourlyRate = convertToHourlyRate();
    const unpaidAdjustedHourlyRate = convertToUnpaidAdjustedHourlyRate();
    const paidAdjustedHourlyRate = convertToPaidAdjustedHourlyRate();
    const totalAdjustedHourlyRate = convertToTotalAdjustedHourlyRate();

    const dailyRate = hourlyRate * (hoursPerWeek / daysPerWeek);
    const weeklyRate = dailyRate * daysPerWeek;
    const biweeklyRate = weeklyRate * 2;
    const monthlyRate = hourlyRate * (hoursPerWeek * (weeksPerYear / 12));
    const semimonthlyRate = monthlyRate / 2;
    const quarterlyRate = monthlyRate * 3;
    const annuallyRate = monthlyRate * 12;

    const dailyRateAdjustedUnpaid = unpaidAdjustedHourlyRate * (hoursPerWeek / daysPerWeek);
    const weeklyRateAdjustedUnpaid = dailyRateAdjustedUnpaid * daysPerWeek;
    const biweeklyRateAdjustedUnpaid = weeklyRateAdjustedUnpaid * 2;
    const monthlyRateAdjustedUnpaid = unpaidAdjustedHourlyRate * (hoursPerWeek * (weeksPerYear / 12));
    const semimonthlyRateAdjustedUnpaid = monthlyRateAdjustedUnpaid / 2;
    const quarterlyRateAdjustedUnpaid = monthlyRateAdjustedUnpaid * 3;
    const annuallyRateAdjustedUnpaid = monthlyRateAdjustedUnpaid * 12;

    const dailyRateAdjustedPaid = paidAdjustedHourlyRate * (hoursPerWeek / daysPerWeek);
    const weeklyRateAdjustedPaid = dailyRateAdjustedPaid * daysPerWeek;
    const biweeklyRateAdjustedPaid = weeklyRateAdjustedPaid * 2;
    const monthlyRateAdjustedPaid = paidAdjustedHourlyRate * (hoursPerWeek * (weeksPerYear / 12));
    const semimonthlyRateAdjustedPaid = monthlyRateAdjustedPaid / 2;
    const quarterlyRateAdjustedPaid = monthlyRateAdjustedPaid * 3;
    const annuallyRateAdjustedPaid = monthlyRateAdjustedPaid * 12;

    const dailyRateTotalAdjusted = totalAdjustedHourlyRate * (hoursPerWeek / daysPerWeek);
    const weeklyRateTotalAdjusted = dailyRateTotalAdjusted * daysPerWeek;
    const biweeklyRateTotalAdjusted = weeklyRateTotalAdjusted * 2;
    const monthlyRateTotalAdjusted = totalAdjustedHourlyRate * (hoursPerWeek * (weeksPerYear / 12));
    const semimonthlyRateTotalAdjusted = monthlyRateTotalAdjusted / 2;
    const quarterlyRateTotalAdjusted = monthlyRateTotalAdjusted * 3;
    const annuallyRateTotalAdjusted = monthlyRateTotalAdjusted * 12;

    const data = {
      labels: ['Hourly', 'Daily', 'Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly', 'Quarterly', 'Annually'],
      datasets: [
        {
          label: 'Unadjusted',
          hourly: hourlyRate.toFixed(2),
          daily: dailyRate.toFixed(2),
          weekly: weeklyRate.toFixed(2),
          biweekly: biweeklyRate.toFixed(2),
          semimonthly: semimonthlyRate.toFixed(2),
          monthly: monthlyRate.toFixed(2),
          quarterly: quarterlyRate.toFixed(2),
          annually: annuallyRate.toFixed(2),
        },
        {
          label: 'Adjusted for Unpaid Days Off',
          hourly: unpaidAdjustedHourlyRate.toFixed(2),
          daily: dailyRateAdjustedUnpaid.toFixed(2),
          weekly: weeklyRateAdjustedUnpaid.toFixed(2),
          biweekly: biweeklyRateAdjustedUnpaid.toFixed(2),
          semimonthly: semimonthlyRateAdjustedUnpaid.toFixed(2),
          monthly: monthlyRateAdjustedUnpaid.toFixed(2),
          quarterly: quarterlyRateAdjustedUnpaid.toFixed(2),
          annually: annuallyRateAdjustedUnpaid.toFixed(2),
        },
        {
          label: 'Adjusted for Paid Days Off',
          hourly: paidAdjustedHourlyRate.toFixed(2),
          daily: dailyRateAdjustedPaid.toFixed(2),
          weekly: weeklyRateAdjustedPaid.toFixed(2),
          biweekly: biweeklyRateAdjustedPaid.toFixed(2),
          semimonthly: semimonthlyRateAdjustedPaid.toFixed(2),
          monthly: monthlyRateAdjustedPaid.toFixed(2),
          quarterly: quarterlyRateAdjustedPaid.toFixed(2),
          annually: annuallyRateAdjustedPaid.toFixed(2),
        },
        {
          label: 'Adjusted for Actual Total Hours Worked',
          hourly: totalAdjustedHourlyRate.toFixed(2),
          daily: dailyRateTotalAdjusted.toFixed(2),
          weekly: weeklyRateTotalAdjusted.toFixed(2),
          biweekly: biweeklyRateTotalAdjusted.toFixed(2),
          semimonthly: semimonthlyRateTotalAdjusted.toFixed(2),
          monthly: monthlyRateTotalAdjusted.toFixed(2),
          quarterly: quarterlyRateTotalAdjusted.toFixed(2),
          annually: annuallyRateTotalAdjusted.toFixed(2),
        },
      ],
    };

    setHourlyRate(hourlyRate);
    setUnpaidAdjustedHourlyRate(unpaidAdjustedHourlyRate);
    setPaidAdjustedHourlyRate(paidAdjustedHourlyRate);
    setTotalAdjustedHourlyRate(totalAdjustedHourlyRate);

    setTableData(data);
  };

  function handleIntervalChange(option: string) {
    let interval = parseFloat(option);

    setInterval(interval);

    //setAmountPaid(parseFloat(((hourlyRate * hoursPerWeek * weeksPerYear) / interval).toFixed(2)));
  }

  const handleAmountPaidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let amountPaid = formatUSDNoFormatting(event.target.value);
    if (isNaN(amountPaid)) {
      setAmountPaid(0);
      return;
    }
    setAmountPaid(amountPaid);
  };

  // Fix the hours per week to handle updating the hourly rate properly
  const handleHoursPerWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let hoursPerWeek = formatUSDNoFormatting(event.target.value);
    if (isNaN(hoursPerWeek)) {
      setHoursPerWeek(0);
      return;
    }
    setHoursPerWeek(hoursPerWeek);
  };

  const handleDaysPerWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let daysPerWeek = formatUSDNoFormatting(event.target.value);
    if (isNaN(daysPerWeek)) {
      setDaysPerWeek(0);
      return;
    }
    setDaysPerWeek(daysPerWeek);
  };

  const handleUnpaidHolidaysPerYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let unpaidHolidaysPerYear = formatUSDNoFormatting(event.target.value);
    if (isNaN(unpaidHolidaysPerYear)) {
      setUnpaidHolidaysPerYear(0);
      return;
    }
    setUnpaidHolidaysPerYear(unpaidHolidaysPerYear);
  };

  const handlePaidHolidaysPerYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let paidHolidaysPerYear = formatUSDNoFormatting(event.target.value);
    if (isNaN(paidHolidaysPerYear)) {
      setPaidHolidaysPerYear(0);
      return;
    }
    setPaidHolidaysPerYear(paidHolidaysPerYear);
  };

  const handleUnpaidVacationDaysPerYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let unpaidVacationDaysPerYear = formatUSDNoFormatting(event.target.value);
    if (isNaN(unpaidVacationDaysPerYear)) {
      setUnpaidVacationDaysPerYear(0);
      return;
    }
    setUnpaidVacationDaysPerYear(unpaidVacationDaysPerYear);
  };

  const handlePaidVacationDaysPerYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let paidVacationDaysPerYear = formatUSDNoFormatting(event.target.value);
    if (isNaN(paidVacationDaysPerYear)) {
      setPaidVacationDaysPerYear(0);
      return;
    }

    setPaidVacationDaysPerYear(paidVacationDaysPerYear);
  };

  useEffect(() => {
    calculateSalary();
  }, [
    amountPaid,
    interval,
    hoursPerWeek,
    daysPerWeek,
    unpaidHolidaysPerYear,
    paidHolidaysPerYear,
    unpaidVacationDaysPerYear,
    paidVacationDaysPerYear,
  ]);

  return (
    /**
     * Salary Calculator - Calculate your salary based on various factors
     * 
     * The Salary Calculator is a powerful tool that allows you to calculate your salary based on different factors such as the amount paid per interval, hours worked per week, and paid/unpaid holidays and vacation days. It provides accurate and reliable salary calculations, helping you make informed financial decisions.
     * 
     * With the Salary Calculator, you can easily determine your net income, taking into account deductions for taxes, unpaid holidays, and unpaid vacation days. It also allows you to choose the payment frequency, providing flexibility in calculating salaries for different work arrangements.
     * 
     * Whether you are an employee or an employer, the Salary Calculator is a valuable resource for understanding the financial impact of various salary components. It helps you optimize your salary structure and make informed decisions about your compensation package.
     * 
     * Start using the Salary Calculator today and gain valuable insights into your salary!
     */
    <Title label='Salary Calculator' description='The Salary Calculator is a powerful tool that allows you to calculate your salary
     based on different factors such as the amount paid per interval, hours worked per week, and paid/unpaid holidays and vacation days.
      It provides accurate and reliable salary calculations, helping you make informed financial decisions. Figure out how much you make per
      hour or per year, based on your yearly salary or hourly rate!' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-4'>
          <LabeledInput
            label={'Amount Paid'}
            value={amountPaid}
            onChange={handleAmountPaidChange}
            type={'number'}
            min={'0'}
            step={'0.01'}
            format={'USD'}
            desc={'The amount of money paid per interval'}
          />
          <Dropdown
            label='Payment Frequency:'
            desc='The frequency at which the amount paid is earned'
            dropdown_type='frequency'
            selected={interval}
            onChange={handleIntervalChange}
          />
          <LabeledInput
            label='Hours Per Week'
            value={hoursPerWeek}
            onChange={handleHoursPerWeekChange}
            type={'number'}
            min={'0'}
            step={'0.01'}
            desc={'The number of hours worked per week'}
          />
          <LabeledInput
            label='Days Per Week'
            value={daysPerWeek}
            onChange={handleDaysPerWeekChange}
            type={'number'}
            min={'0'}
            max={'7'}
            desc={'The number of days worked per week'}
          />
          <LabeledInput
            label='Holidays Per Year (Unpaid)'
            value={unpaidHolidaysPerYear}
            onChange={handleUnpaidHolidaysPerYearChange}
            type={'number'}
            min={'0'}
            desc={'The number of unpaid holidays per year'}
          />
          <LabeledInput
            label='Holidays Per Year (Paid)'
            value={paidHolidaysPerYear}
            onChange={handlePaidHolidaysPerYearChange}
            type={'number'}
            min={'0'}
            desc={'The number of paid holidays per year'}
          />
          <LabeledInput
            label='Vacation Days Per Year (Unpaid)'
            value={unpaidVacationDaysPerYear}
            onChange={handleUnpaidVacationDaysPerYearChange}
            type={'number'}
            min={'0'}
            desc={'The number of unpaid vacation days per year'}
          />
          <LabeledInput
            label='Vacation Days Per Year (Paid)'
            value={paidVacationDaysPerYear}
            onChange={handlePaidVacationDaysPerYearChange}
            type={'number'}
            min={'0'}
            desc={'The number of paid vacation days per year'}
          />
        </div>
        <div className='col-lg-8'>
          <SalaryTable data={tableData} />
        </div>
      </div>
    </Title>
  );
};

export default SalaryCalculator;
