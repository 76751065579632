import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import LabeledInput from '../../LabeledInput/LabeledInput';
import Title from '../../Title/Title';
import { formatUSD, formatUSDNoFormatting } from '../../../types/calculator';
import Table from '../../Table/Table';
import Dropdown from '../../Dropdowns/Dropdown';

const CompoundInterestCalculator = () => {
  const [principal, setPrincipal] = useState<number>(1000);
  const [interestRate, setInterestRate] = useState<number>(10);
  const [years, setYears] = useState<number>(10);
  const [monthlyContribution, setMonthlyContribution] = useState<number>(0);
  const [interestRateVariance, setInterestRateVariance] = useState<number>(0);
  const [chartData, setChartData] = useState<any>({});
  const [compoundFrequency, setCompoundFrequency] = useState(1);
  const [contributionIncreaseRate, setContributionIncreaseRate] = useState<number>(0);

  const calculateInterest = () => {
    const data_points: any[] = [];
    const label_points: any[] = [];
    const data_points_pos: any[] = [];
    const data_points_neg: any[] = [];
    const data_points_cont: any[] = [];

    const data = {
      labels: label_points,
      datasets: [
        {
          label: 'Positive Variance',
          data: data_points_pos,
          fill: false,
          borderColor: 'rgb(75, 192, 120)',
          backgroundColor: 'rgba(75, 192, 120)',
          tension: 0.1,
          interestRate: interestRate + interestRateVariance,
        },
        {
          label: 'Balance',
          data: data_points,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192)',
          tension: 0.1,
          interestRate: interestRate,
        },
        {
          label: 'Negative Variance',
          data: data_points_neg,
          fill: false,
          borderColor: 'rgb(192, 75, 75)',
          backgroundColor: 'rgba(192, 75, 75)',
          tension: 0.1,
          interestRate: interestRate - interestRateVariance,
        },
        {
          label: 'Total Contributions',
          data: data_points_cont,
          fill: false,
          borderColor: 'rgb(128, 128, 128)',
          backgroundColor: 'rgba(128, 128, 128)',
          tension: 0.1,
          interestRate: 0,
        },
      ],
    };

    let principalCopy = principal;
    let principalPosCopy = principal;
    let principalNegCopy = principal;
    let totalContributions = principal;
    let monthlyContributionInc = monthlyContribution;

    const ratePerPeriod = interestRate / compoundFrequency;
    const ratePerPeriodVariance = interestRateVariance / compoundFrequency;

    for (let i = 0; i <= years; i++) {
      if (i !== 0) {
        for (let n = 0; n < compoundFrequency; n++) {
          const interestEarned = principalCopy * (ratePerPeriod / 100);
          const interestEarnedPos = principalPosCopy * ((ratePerPeriod + ratePerPeriodVariance) / 100);
          const interestEarnedNeg = principalNegCopy * ((ratePerPeriod - ratePerPeriodVariance) / 100);
          principalCopy += interestEarned + monthlyContributionInc * (12 / compoundFrequency);
          principalPosCopy += interestEarnedPos + monthlyContributionInc * (12 / compoundFrequency);
          principalNegCopy += interestEarnedNeg + monthlyContributionInc * (12 / compoundFrequency);
          totalContributions += monthlyContributionInc * (12 / compoundFrequency);
        }
        monthlyContributionInc = monthlyContributionInc * (1 + contributionIncreaseRate / 100);
      }

      data.labels.push(`Year ${i}`);
      data.datasets[0].data.push(parseFloat(principalPosCopy.toFixed(2)));
      data.datasets[1].data.push(parseFloat(principalCopy.toFixed(2)));
      data.datasets[2].data.push(parseFloat(principalNegCopy.toFixed(2)));
      data.datasets[3].data.push(parseFloat(totalContributions.toFixed(2)));
    }

    setChartData(data);
  };

  const handlePrincipalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let principal = formatUSDNoFormatting(event.target.value);
    if (isNaN(principal)) {
      setPrincipal(0);
      return;
    }
    setPrincipal(principal);
  };

  const handleInterestRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let interestRate = formatUSDNoFormatting(event.target.value);
    if (isNaN(interestRate)) {
      setInterestRate(0);
      return;
    }
    setInterestRate(interestRate);
  };

  const handleYearsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let years = formatUSDNoFormatting(event.target.value);
    if (isNaN(years)) {
      setYears(0);
      return;
    }
    setYears(years);
  };

  const handleMonthlyContributionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let monthlyContribution = formatUSDNoFormatting(event.target.value);
    if (isNaN(monthlyContribution)) {
      setMonthlyContribution(0);
      return;
    }
    setMonthlyContribution(monthlyContribution);
  };

  const handleContributionIncreaseRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let contributionIncreaseRate = formatUSDNoFormatting(event.target.value);
    if (isNaN(contributionIncreaseRate)) {
      setContributionIncreaseRate(0);
      return;
    }
    setContributionIncreaseRate(contributionIncreaseRate);
  };

  const handleInterestRateVarianceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let interestRateVariance = formatUSDNoFormatting(event.target.value);
    if (isNaN(interestRateVariance)) {
      setInterestRateVariance(0);
      return;
    }
    setInterestRateVariance(interestRateVariance);
  };

  function handleCompoundFrequencyChange(option: string) {
    setCompoundFrequency(formatUSDNoFormatting(option));
  }

  useEffect(() => {
    calculateInterest();
  }, [
    principal,
    interestRate,
    years,
    monthlyContribution,
    interestRateVariance,
    compoundFrequency,
    contributionIncreaseRate,
  ]); // Empty array means the effect only runs on mount

  return (
    <Title label='Compound Interest Calculator' description='Unlock the magic of compound interest and see how your money can grow
     exponentially over time! This powerful calculator visualizes your investment potential, allowing you to explore different rates
      of return, wealth building, retirement planning, and calculating your investments growth over time. With the power of compounding you can achieve financial freedom!' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-4'>
          <br />
          <br />
          <LabeledInput
            label={'Principal:'}
            desc={'The initial amount of money you are investing'}
            type={'number'}
            value={principal}
            format='USD'
            onChange={handlePrincipalChange}
          />
          <LabeledInput
            label={'Monthly Contribution:'}
            desc={'The amount of money you are contributing each month'}
            type={'number'}
            value={monthlyContribution}
            format='USD'
            onChange={handleMonthlyContributionChange}
          />
          <LabeledInput
            label={'Contribution Increase Rate:'}
            desc={'The rate at which your contribution increases each year'}
            type={'number'}
            step={'0.01'}
            value={contributionIncreaseRate}
            format='%'
            onChange={handleContributionIncreaseRateChange}
          />
          <LabeledInput
            label={'Years:'}
            desc={'The number of years that you will invest'}
            type={'number'}
            value={years}
            onChange={handleYearsChange}
          />
          <LabeledInput
            label={'Interest Rate:'}
            desc={'The interest rate you expect to earn'}
            type={'number'}
            step={'0.01'}
            value={interestRate}
            format='%'
            onChange={handleInterestRateChange}
          />
          <LabeledInput
            label={'Interest Rate Variance:'}
            desc={'The variance in your interest rate. This will show you the best and worst case scenarios'}
            type={'number'}
            step={'0.01'}
            value={interestRateVariance}
            format='%'
            onChange={handleInterestRateVarianceChange}
          />
          <Dropdown
            label='Compound Frequency:'
            desc='The number of times per year the accumulated interest is paid out'
            dropdown_type='frequency'
            selected={compoundFrequency}
            onChange={handleCompoundFrequencyChange}
          />
          <button onClick={calculateInterest}>Calculate</button>
        </div>
        <div className='col-lg-8' style={{ height: "100%"}}>
          {Object.keys(chartData).length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <div className='chart-size'>
              <Line
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        callback: (label: any) => `${formatUSD(label)}`,
                      },
                    },
                  },
                  elements: {
                    point: {
                      hitRadius: 25,
                    },
                  },
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                      callbacks: {
                        label: function (context: any) {
                          const label = context.dataset.label;
                          const value = context.parsed.y;
                          const rate = context.dataset.interestRate;

                          const totalLabel = `${label} (${rate}%): ${formatUSD(value)}`;
                          return totalLabel;
                        },
                      },
                    },
                  },
                }}
              />
              </div>
            </div>
          )}
        </div>
      </div>
      <Table title={'Compound Interest Breakdown'} data={chartData} />
    </Title>
  );
};

export default CompoundInterestCalculator;
