import { NavLink } from 'react-router-dom';

export function Footer() {
  return (
    <footer style={{ padding: '20px 0' }}>
      <div className='container'>
        <NavLink exact to={'/'} className={'logo-font'}>
          fire
        </NavLink>
        <span className='attribution'>
          A financial learning project from{' '}
          <NavLink exact to={`https://twitter.com/TheTrueFIREGuy`}>
            {' '}
            FIRE Guy
          </NavLink>
          . FIRE Guy does not provide financial advice and does not issue recommendations or offers to buy stock or sell
          any security.
          <br />
          Information is provided 'as-is' and solely for informational purposes and is not advice. FIRE Guy does not
          bear any responsibility for any losses or damage that may occur as a result of reliance on this data. Powered
          by
        </span>
        <a href='https://buttercms.com'>
          <img width='108' height='27' src='//fireguy.io/butter-cms.png' alt='Butter CMS' />
        </a>
      </div>
    </footer>
  );
}
