import React from 'react';
import Title from '../Title/Title';
import { formatUSD } from '../../types/calculator';

interface TableProps {
  title: string;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
  startRightAfterRows?: number; // Number of rows after which the table should start on the right
}

const Table: React.FC<TableProps> = ({ title, data, startRightAfterRows }) => {
  if (!data.labels || data.labels.length === 0) {
    return <div>No data available.</div>;
  }

  const tableContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '100%', // Set the maximum width of the container to 100%
    overflowX: 'auto', // Enable horizontal scrolling if needed
  };

  const tableStyle: React.CSSProperties = {
    width: '100%',
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <Title label={title}>
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th></th>
                {data.datasets.map((dataset, datasetIndex) => (
                  <th key={datasetIndex}>{dataset.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.labels.map((label, labelIndex) => (
                <tr key={labelIndex}>
                  <td style={{ width: '100px' }}>{label}</td>
                  {data.datasets.map((dataset, datasetIndex) => {
                    const cellValue = dataset.data[labelIndex] !== undefined ? dataset.data[labelIndex] : 0;
                    return (
                      <td key={datasetIndex} style={{ width: '100px' }}>
                        {formatUSD(cellValue)}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Title>
    </div>
  );
};

export default Table;
