import { range } from 'ramda';

export function Pagination({
  currentPage,
  count,
  itemsPerPage,
  onPageChange,
}: {
  currentPage: number;
  count: number;
  itemsPerPage: number;
  onPageChange?: (index: number) => void;
}) {
  // Calculate the range of page indices to display
  const totalPages = Math.ceil(count / itemsPerPage);
  const startPage = Math.max(1, currentPage - 1); // Display 1 page before the current page
  const endPage = Math.min(totalPages, startPage + 2); // Display 2 indices in total

  const handlePageClick = (index: number, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // Prevent the default link behavior
    onPageChange && onPageChange(index);
  };

  return (
    <nav>
      <ul className='pagination'>
        {range(startPage, endPage + 1).map((index) => (
          <li
            key={index}
            className={`page-item${currentPage !== index ? '' : ' active'}`}
            onClick={() => onPageChange && onPageChange(index)}
          >
            <a
              className='page-link'
              aria-label={`Go to page number ${index}`}
              href='#' // Add a placeholder href attribute
              onClick={(event) => handlePageClick(index, event)}
            >
              {index}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
