import { None, Option, Some } from '@hqoss/monads';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../../../types/post';

export interface HomeState {
  tags: Option<Tag[]>;
  selectedTab: string;
  selectedTagSlug: string;
}

const initialState: HomeState = {
  tags: None,
  selectedTab: 'Global Feed',
  selectedTagSlug: '',
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    startLoadingTags: () => initialState,
    loadTags: (state, { payload: tags }: PayloadAction<Tag[]>) => {
      state.tags = Some(tags);
    },
    changeTab: (state, { payload: { tab, slug } }: PayloadAction<{ tab: string; slug: string }>) => {
      state.selectedTab = tab;
      state.selectedTagSlug = slug;
    },
  },
});

export const { startLoadingTags, loadTags, changeTab } = slice.actions;

export default slice.reducer;
