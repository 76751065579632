import React, { useState } from 'react';
import settings from '../../config/settings';

function EmailSubscriptionForm() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false); // New state to track subscription status

  const handleSubscribe = async () => {
    // Check if all three fields are filled
    if (firstName.trim() === '' || lastName.trim() === '' || email.trim() === '') {
      alert('Please fill in all fields before subscribing.');
      return;
    }

    try {
      // Add the email to your Mailchimp audience
      const response = await subscribe(firstName, lastName, email);

      console.log('Successfully subscribed:', response);
      setSubscribed(true); // Update the state to show confirmation message and hide input fields
    } catch (error) {
      console.error('Error subscribing:', error);
      // Handle errors here (e.g., display an error message to the user)
    }
  };

  return (
    <div className='subscription-form'>
      {subscribed ? (
        <div className='confirmation-message'>Thank you for subscribing!</div>
      ) : (
        <>
          <h4>Subscribe to our newsletter & updates!</h4>
          <br />
          <input
            type='text'
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input type='text' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
          <button onClick={handleSubscribe}>Subscribe</button>
        </>
      )}
    </div>
  );
}

export async function subscribe(firstName: string, lastName: string, emailAddress: string): Promise<string> {
  const api_key = settings.lambdaApiKey;
  const url = settings.lambdaApiUrl + settings.lambdaApiStage + settings.lambdaApiNewsletterEndpoint;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'x-api-key': api_key,
      },
      body: JSON.stringify({ firstName, lastName, emailAddress }),
    });
    const data = await response.json();
    console.log(data);
    return JSON.stringify(data);
  } catch (error) {
    console.error(error);
    return '';
  }
}

export default EmailSubscriptionForm;
