import { array, boolean, Decoder, iso8601, number, object, string } from 'decoders';

export interface Author {
  first_name: string;
  last_name: string;
  email: string;
  slug: string;
  bio: string;
  title: string;
  linkedin_url: string;
  facebook_url: string;
  pinterest_url: string;
  instagram_url: string;
  twitter_handle: string;
  profile_image: string;
}

export const authorDecoder: Decoder<Author> = object({
  first_name: string,
  last_name: string,
  email: string,
  slug: string,
  bio: string,
  title: string,
  linkedin_url: string,
  facebook_url: string,
  pinterest_url: string,
  instagram_url: string,
  twitter_handle: string,
  profile_image: string,
});
