import { None, Option, Some } from '@hqoss/monads';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Posts, MetaPost, Data, PostsFilters } from '../../types/post';

export interface DataViewerPost {
  post_data: Data;
  isSubmitting: boolean;
}

export interface PostViewerState {
  posts: Option<readonly DataViewerPost[]>;
  currentPage: number;
  postsCount: number;
}

const initialState: PostViewerState = {
  posts: None,
  currentPage: 1,
  postsCount: 0,
};

const slice = createSlice({
  name: 'postViewer',
  initialState,
  reducers: {
    startLoadingPosts: () => initialState,
    loadPosts: (state, { payload: { meta, data } }: PayloadAction<Posts>) => {
      if (data && Array.isArray(data)) {
        state.posts = Some(data.map((post_data) => ({ post_data, isSubmitting: false })));
        state.postsCount = meta.count;
      } else {
        console.error('Invalid data format:', data);
      }
    },
    changePage: (state, { payload: page }: PayloadAction<number>) => {
      state.currentPage = page;
      state.posts = None;
    },
  },
});

export const { startLoadingPosts, loadPosts, /*startSubmittingFavorite, endSubmittingFavorite,*/ changePage } =
  slice.actions;

export default slice.reducer;
