import { array, Decoder, either, iso8601, number, object, string } from 'decoders';
import { Author, authorDecoder } from './author';

export interface Posts {
  meta: MetaPosts;
  data: Data[];
}

export interface Post {
  meta: MetaPost;
  data: Data;
}

export interface MetaPosts {
  count: number;
  next_page?: number | string;
  previous_page?: number | string;
}

export interface MetaPost {
  next_post?: ChangePost;
  previous_post?: ChangePost;
}

export interface ChangePost {
  slug?: string | null;
  title?: string | null;
  featured_image?: string | null;
}

export interface Data {
  url: string | null;
  created: Date;
  published: Date;
  author: Author;
  categories: Category[];
  tags: Tag[];
  featured_image: string | null;
  featured_image_alt: string;
  slug: string;
  title: string;
  body: string;
  summary: string;
  seo_title: string;
  meta_description: string;
  status: string;
}

export interface Category {
  name: string;
  slug: string;
}

export interface Tag {
  name: string;
  slug: string;
}

export interface PostsFilters {
  page?: number;
  page_size?: number;
  preview?: number;
  exclude_body?: boolean;
  author_slug?: string;
  category_slug?: string;
  tag_slug?: string;
}

export const changePostDecoder: Decoder<ChangePost> = object({
  slug: string,
  title: string,
  featured_image: string,
});

export const metaPostDecoder: Decoder<MetaPost> = object({
  next_post: changePostDecoder,
  previous_post: changePostDecoder,
});

// Define a decoder for next_page and previous_page that allows numbers or strings
const nextPageDecoder: Decoder<number | string> = either(number, string);
const previousPageDecoder: Decoder<number | string> = either(number, string);

export const metaPostsDecoder: Decoder<MetaPosts> = object({
  count: number,
  next_page: nextPageDecoder,
  previous_page: previousPageDecoder,
});

export const dataDecoder: Decoder<Data> = object({
  url: string,
  created: iso8601,
  published: iso8601,
  author: authorDecoder,
  categories: array(object({ name: string, slug: string })),
  tags: array(object({ name: string, slug: string })),
  featured_image: string,
  featured_image_alt: string,
  slug: string,
  title: string,
  body: string,
  summary: string,
  seo_title: string,
  meta_description: string,
  status: string,
});

export const postsDecoder: Decoder<Posts> = object({
  meta: metaPostsDecoder,
  data: array(dataDecoder),
});

export const postDecoder: Decoder<Post> = object({
  meta: metaPostDecoder,
  data: dataDecoder,
});
