import React from 'react';

const SocialMediaLinks = () => {
    const socialMedia = [
        { name: 'Twitter', icon: 'https://fireguy.io/images/socials/x.png', link: 'https://twitter.com/TheTrueFIREGuy' },
        { name: 'Instagram', icon: 'https://fireguy.io/images/socials/insta.png', link: 'https://www.instagram.com/TheTrueFIREGuy/' },
        { name: 'YouTube', icon: 'https://fireguy.io/images/socials/yt.png', link: 'https://www.youtube.com/@TheTrueFIREGuy'},
        { name: 'TikTok', icon: 'https://fireguy.io/images/socials/tiktok.png', link: 'https://www.tiktok.com/@thetruefireguy'},
        { name: 'Threads', icon: 'https://fireguy.io/images/socials/threads.png', link: 'https://www.threads.net/@thetruefireguy'}
    ];

    return (
        <div>
            {socialMedia.map((item) => (
                <a key={item.name} href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={item.icon} alt={item.name} style={{ width: 'auto', height: '20px', borderRadius: '0', paddingRight: '8px' }} />
                </a>
            ))}
        </div>
    );
};

export default SocialMediaLinks;