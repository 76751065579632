import React, { useEffect, useRef, useState } from 'react';

function DropdownBox({
  selectedOption,
  options,
  onChange,
}: {
  selectedOption: number;
  options: any;
  onChange: (option: any) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    window.addEventListener('click', handleOutsideClick, true);

    return () => {
      window.removeEventListener('click', handleOutsideClick, true);
    };
  }, []);

  const handleSelect = (option: any) => {
    onChange(option.value);
    setIsOpen(false);
  };

  const filteredOptions = options.filter((option: { value: number }) => option.value !== selectedOption); // Exclude the selected option

  const styles = {
    dropdown: {
      position: 'relative' as 'relative', // Explicitly type the position
      display: 'inline-block' as 'inline-block', // Explicitly type the display
    },
    dropdownToggle: {
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: 4,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px 10px',
      marginBottom: '5px',
      width: '100px',
    },
    dropdownMenu: {
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: 4,
      position: 'absolute' as 'absolute', // Explicitly type the position
      //top: '50%',
      width: '100%',
      zIndex: 2,
    },
    dropdownItem: {
      backgroundColor: '#fff',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      padding: '5px 10px',
      width: '100%',
    },
  };

  return (
    <div style={styles.dropdown} ref={dropdownRef}>
      <button style={styles.dropdownToggle} onClick={() => setIsOpen(!isOpen)}>
        <strong>{options.find((option: any) => option.value === selectedOption)?.label}</strong>
        <span>{isOpen ? '▲' : '▼'}</span>
      </button>
      {isOpen && (
        <div style={styles.dropdownMenu}>
          {filteredOptions.map((option: any) => (
            <button key={option.value} style={styles.dropdownItem} onClick={() => handleSelect(option)}>
              <strong>{option.label}</strong>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownBox;
