import Label from '../Label/Label';

function LabeledInput({
  label,
  desc,
  type,
  value,
  step,
  min,
  max,
  onChange,
}: {
  label: string;
  desc?: String;
  type: string;
  value: any;
  step?: string;
  min: string;
  max: string;
  onChange: (option: any) => void;
}) {
  const percentage = ((value - parseInt(min)) / (parseInt(max) - parseInt(min))) * 100;
  return (
    <div className='input-wrapper'>
      <Label label={label} desc={desc} />
      <input type={type} step={step} min={min} max={max} value={value} onChange={onChange} />
    </div>
  );
}

export default LabeledInput;
