import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { None, Option, Some } from '@hqoss/monads';
import { Post, Data } from '../../../types/post';
import { Comment } from '../../../types/comment';
import { Profile } from '../../../types/profile';

export interface CommentSectionState {
  comments: Option<Comment[]>;
  commentBody: string;
  submittingComment: boolean;
}

export interface PostPageState {
  post: Option<Data>;
  commentSection: CommentSectionState;
}

const initialState: PostPageState = {
  post: None,
  commentSection: {
    comments: None,
    commentBody: '',
    submittingComment: false,
  },
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initializePostPage: () => initialState,
    loadPost: (state, { payload: post }: PayloadAction<Data>) => {
      state.post = Some(post);
    },
    loadComments: (state, { payload: comments }: PayloadAction<Comment[]>) => {
      state.commentSection.comments = Some(comments);
      state.commentSection.commentBody = '';
      state.commentSection.submittingComment = false;
    },
    // TODO: Need to fix this author. Need to make an author page
    /*updateAuthor: (state, { payload: author }: PayloadAction<Profile>) => {
      state.post = state.post.map((post) => ({ ...post, author }));
    },*/
    updateCommentBody: (state, { payload: commentBody }: PayloadAction<string>) => {
      state.commentSection.commentBody = commentBody;
    },
    startSubmittingComment: (state) => {
      state.commentSection.submittingComment = true;
    },
  },
});

export const {
  initializePostPage,
  loadPost,
  loadComments,
  //updateAuthor,
  updateCommentBody,
  startSubmittingComment,
} = slice.actions;

export default slice.reducer;
