import React, { useState, useEffect } from 'react';
import settings from '../../config/settings';
import { Stock } from '../../types/stock';

export function renderBanner() {
  return (
    <div className='banner'>
      <div className='container'>
        <h1 className='logo-font'>fire</h1>
        <p>Become financially independent and unlock true freedom</p>
        <p style={{ fontSize: 18 }}>Learn what you were never taught in school</p>
      </div>
      {renderStockTickers()}
    </div>
  );
}

function renderStockTickers() {
  const [stockData, setStockData] = useState<string>('');
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    getStockData().then((data) => setStockData(data));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  if (stockData != '') {
    const stock_array: Stock[] = JSON.parse(stockData);
    const limited_stock_array = screenSize <= 768 ? stock_array.slice(0, 3) : stock_array;
    return (
      <div className='stock-banner'>
        {limited_stock_array.map((stock: Stock, index: number) => (
          <div key={index} className='stock-item'>
            <div className='stock-name'>{getCompanyName(stock.ticker)}</div>
            <div className='stock-ticker'>{stock.ticker}</div>
            <div className='stock-price' style={{ color: getChangeColor(stock.change) }}>
              {formatDecimal(stock.price)}
            </div>
            <div className='row'>
              <div
                //className='stock-change'
                style={{
                  color: getChangeColor(stock.change),
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  width: screenSize <= 1378 ? '100%' : '50%',
                  paddingRight: screenSize <= 1378 ? '0' : '3px',
                }}
              >
                {formatChange(stock.change)}
              </div>
              <div
                //className='stock-percent-change'
                style={{
                  color: getChangeColor(stock.change),
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  width: screenSize <= 1378 ? '100%' : '50%',
                  paddingRight: screenSize <= 1378 ? '0' : '3px',
                }}
              >
                {formatChangePercent(stock.percent)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <div className='stock-banner'></div>;
  }
}

function formatChange(change: string): string {
  let changeFloat = parseFloat(change);
  let formattedChange = formatDecimal(change);

  if (changeFloat > 0) {
    formattedChange = '+' + formattedChange;
  }

  return formattedChange;
}

function formatDecimal(amount: string): string {
  let amountFloat = parseFloat(amount);
  return amountFloat.toFixed(2).toString();
}

function getChangeColor(change: string): string {
  let changeFloat = parseFloat(change);

  if (changeFloat < 0) {
    return '#ff0000';
  } else if (changeFloat > 0) {
    return '#00aa00';
  } else {
    return 'black';
  }
}

function formatChangePercent(change: string): string {
  let changeFloat = parseFloat(change);
  let formattedChange = formatDecimal(change);

  if (changeFloat > 0) {
    formattedChange = '+' + formattedChange;
  }

  formattedChange = '(' + formattedChange;

  return formattedChange + '%)';
}

function getCompanyName(ticker: string): string {
  if (ticker === 'SPY') {
    return 'S&P 500';
  } else if (ticker === 'QQQ') {
    return 'Nasdaq Composite';
  } else if (ticker === 'DIA') {
    return 'Dow Jones';
  } else if (ticker === 'IWM') {
    return 'Russell 2000';
  } else if (ticker === 'VTI') {
    return 'Total Stock Market';
  } else if (ticker === 'AAPL') {
    return 'Apple, Inc.';
  } else if (ticker === 'MSFT') {
    return 'Microsoft, Inc.';
  } else if (ticker === 'TSLA') {
    return 'Tesla, Inc.';
  } else {
    return ticker;
  }
}

async function getStockData(): Promise<string> {
  const api_key = settings.lambdaApiKey;
  const url = settings.lambdaApiUrl + settings.lambdaApiStage + settings.lambdaApiStockEndpoint;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': api_key,
      },
    });
    const data = await response.json();

    return JSON.stringify(data);
  } catch (error) {
    console.error(error);
    return '';
  }
}
