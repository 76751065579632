import { useParams } from 'react-router-dom';
import MortgageCalculator from '../../Calculators/Loans/MortgageCalculator';
import CompoundInterestCalculator from '../../Calculators/Investments/CompoundInterestCalculator';
import EarlyRetirementCalculator from '../../Calculators/Retirement/EarlyRetirementCalculator';
import SalaryCalculator from '../../Calculators/Miscellaneous/SalaryCalculator';
import AutoLoanCalculator from '../../Calculators/Loans/AutoLoanCalculator';
import InflationIncomeCalculator from '../../Calculators/Miscellaneous/InflationIncomeCalculator';
import DebtAvalancheCalculator from '../../Calculators/Loans/DebtAvalancheCalculator';
import DebtSnowballCalculator from '../../Calculators/Loans/DebtSnowballCalculator';

export function Calculator() {
  const { slug } = useParams<{ slug: string }>();
  return (
    <div className='home-page'>
      <div className='calculators-page'>{renderCalculator(slug)}</div>
    </div>
  );
}

export function renderCalculator(slug: string) {
  switch (slug) {
    case 'mortgage-calculator':
      return <MortgageCalculator />;
    case 'compound-interest-calculator':
      return <CompoundInterestCalculator />;
    case 'early-retirement-calculator':
      return <EarlyRetirementCalculator />;
    case 'salary-calculator':
      return <SalaryCalculator />;
    case 'auto-loan-calculator':
      return <AutoLoanCalculator />;
    case 'inflation-income-calculator':
      return <InflationIncomeCalculator />;
    case 'debt-avalanche-calculator':
      return <DebtAvalancheCalculator />;
    case 'debt-snowball-calculator':
      return <DebtSnowballCalculator />;
    default:
      return <div>Calculator not found</div>;
  }
}
