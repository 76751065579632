import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Data, Tag } from '../../types/post';

// Need to add support for:
var favorited = true;
var favoritesCount = 2;

// Create a Link to the Authors page (need to create this) instead of using /profile/username
/*        <button
className={`btn btn-sm pull-xs-right ${favorited ? 'btn-primary' : 'btn-outline-primary'}`}
aria-label='Toggle Favorite'
disabled={isSubmitting}
onClick={onFavoriteToggle}
>
<i className='ion-heart'></i> {favoritesCount}
</button>*/

export function PostDataPreview({
  post_data: {
    url,
    created,
    published,
    author: {
      first_name,
      last_name,
      email,
      slug: author_slug,
      bio,
      title: author_title,
      linkedin_url,
      facebook_url,
      pinterest_url,
      instagram_url,
      twitter_handle,
      profile_image,
    },
    categories,
    tags,
    featured_image,
    featured_image_alt,
    slug: data_slug,
    title: data_title,
    body,
    summary,
    seo_title,
    meta_description,
    status,
  },
  isSubmitting,
  onFavoriteToggle,
}: {
  post_data: Data;
  isSubmitting: boolean;
  onFavoriteToggle?: () => void;
}) {
  return (
    <div className='article-preview'>
      <div className='article-meta'>
        <a href={`https://twitter.com/${twitter_handle}`} className='author'>
          <img src={profile_image || undefined} />
        </a>
        <div className='info'>
          <a href={`https://twitter.com/${twitter_handle}`} className='author'>
            {first_name + ' ' + last_name}
          </a>
          <span className='date'>{format(published, 'PP')}</span>
        </div>
      </div>
      <Link href={`/post/${data_slug}`} className='preview-link' to={`/post/${data_slug}`}>
        <h1>{data_title}</h1>
        <p>{summary}</p>
        <span>Read more...</span>
        <TagList tagList={tags} />
      </Link>
    </div>
  );
}

export function TagList({ tagList }: { tagList: Tag[] }) {
  return (
    <ul className='tag-list'>
      {tagList.map((tag) => (
        <li key={tag.slug} className='tag-default tag-pill tag-outline'>
          {tag.name}
        </li>
      ))}
    </ul>
  );
}
