import { useEffect, useState } from 'react';
import LabeledInputDesktop from './LabeledInputDesktop';
import LabeledInputMobile from './LabeledInputMobile';

function LabeledInput({
  label,
  desc,
  type,
  value,
  step,
  min,
  max,
  format,
  onChange,
}: {
  label: string;
  desc?: String;
  type: string;
  value: any;
  step?: string;
  min?: string;
  max?: string;
  format?: string;
  onChange: (option: any) => void;
}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Call once to set the initial value based on the screen size.

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <LabeledInputMobile
        label={label}
        desc={desc}
        type={type}
        value={value}
        step={step}
        min={min}
        max={max}
        format={format}
        onChange={onChange}
      />
    );
  } else {
    return (
      <LabeledInputDesktop
        label={label}
        desc={desc}
        type={type}
        value={value}
        step={step}
        min={min}
        max={max}
        format={format}
        onChange={onChange}
      />
    );
  }
}

export default LabeledInput;
