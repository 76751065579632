import { useEffect, useRef, useState } from 'react';
import { formatUSD, parseUSD } from '../../types/calculator';
import Label from '../Label/Label';
import { parse } from 'date-fns';

function LabeledInputDesktop({
  label,
  desc,
  type,
  value,
  step,
  min,
  max,
  format,
  onChange,
}: {
  label: string;
  desc?: String;
  type: string;
  value: any;
  step?: string;
  min?: string;
  max?: string;
  format?: string;
  onChange: (option: any) => void;
}) {
  const [isShown, setIsShown] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isShown && isSelected && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isShown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsSelected(false);
        let text = document.getSelection();
        if (text !== null && text.toString() !== '') {
          let checkedText = text.toString();
          const hasNoUncommonChar = Array.from(checkedText).every((char) => String(value).includes(char));
          console
            .log
            /*'hasNoUncommonChar: ' + hasNoUncommonChar + ' checkedText: ' + checkedText + ' value: ' + value + ''*/
            ();
          if (!hasNoUncommonChar) {
            setIsShown(true);
          }
        } else {
          setIsShown(true);
        }
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [label, value]);

  let formattedValue = value;

  if (format === 'USD') {
    formattedValue = formatUSD(value);
  } else if (format === '%') {
    formattedValue = `${value}%`;
  }

  if (type === 'range') {
    return (
      <div className='input-wrapper'>
        <Label label={label} desc={desc} />
        <input type={type} step={step} min={min} max={max} value={value} onChange={onChange} />
      </div>
    );
  }

  return (
    <div className='input-wrapper'>
      <Label label={label} desc={desc} />

      {(!isShown || isSelected) && (
        <input
          ref={inputRef}
          type={type}
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          onMouseDown={() => setIsSelected(true)}
          onMouseLeave={() => setIsShown(!isSelected)}
          style={{ zIndex: 2, top: 0, left: 0 }}
        />
      )}
      {isShown && !isSelected && (
        <input value={formattedValue} onMouseEnter={() => setIsShown(false)} style={{ zIndex: 1 }} readOnly={true} />
      )}
    </div>
  );
}

export default LabeledInputDesktop;
