export default {
  baseApiUrl: '',
  baseButterUrl: 'https://api.buttercms.com/v2/',
  // We're going to have to secure the following by making it only able to be hit by
  butterApiKey: '9188392ab4fadee99c530407eb42d83f8eed27f4',
  lambdaApiUrl: 'https://u8aygnb8k3.execute-api.us-east-1.amazonaws.com',
  lambdaApiKey: 'aGTrZSPuae3SIe1QK9IAc2AjK2DvSxbv6xLmzm5v',
  lambdaApiStage: '/prod',
  lambdaApiStockEndpoint: '/stock',
  lambdaApiNewsletterEndpoint: '/newsletter',
};
