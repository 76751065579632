const investments = { 'Compound Interest Calculator': 'compound-interest-calculator' };
const loans = {
  'Mortgage Calculator': 'mortgage-calculator',
  'Auto Loan Calculator': 'auto-loan-calculator',
  //'Student Loan Calculator': 'student-loan-calculator',
  'Debt Snowball Calculator': 'debt-snowball-calculator',
  'Debt Avalanche Calculator': 'debt-avalanche-calculator',
};
const retirement = { 'FIRE calculator': 'early-retirement-calculator' };
const miscellaneous = {
  'Salary Calculator': 'salary-calculator',
  'Inflation Income Calculator': 'inflation-income-calculator',
};

export const calcMap: { [key: string]: { [key: string]: string } } = {
  investments: investments,
  loans: loans,
  retirement: retirement,
  miscellaneous: miscellaneous,
};

export enum calcTypes {
  INVESTMENT = 'investments',
  LOANS = 'loans',
  RETIREMENT = 'retirement',
  MISCELLANEOUS = 'miscellaneous',
}

export interface Calculator {
  name: string;
  slug: string;
  type: string;
}

export function formatUSD(value: number): string {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function parseUSD(value: string): string {
  // Remove any non-numeric characters from the string
  return value.replace(/[^\d.-]/g, '');
}

export function formatUSDNoFormatting(value: string): number {
  return parseFloat(parseFloat(value).toFixed(2));
}
