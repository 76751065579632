import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import LabeledInput from '../../LabeledInput/LabeledInput';
import Title from '../../Title/Title';
import { formatUSD } from '../../../types/calculator';
import LabeledInputRange from '../../LabeledInput/LabeledInputRange';
import Table from '../../Table/Table';

const EarlyRetirementCalculator = () => {
  const [currentAnnualIncome, setCurrentAnnualIncome] = useState<number>(50000);
  const [currentAnnualIncomeGrowth, setCurrentAnnualIncomeGrowth] = useState<number>(3);
  const [currentAnnualSavings, setCurrentAnnualSavings] = useState<number>(20000);
  const [currentAnnualExpenses, setCurrentAnnualExpenses] = useState<number>(30000);
  const [currentAnnualExpenseGrowth, setCurrentAnnualExpenseGrowth] = useState<number>(3);
  const [currentSavingsRate, setCurrentSavingsRate] = useState<number>(40);
  const [currentPortfolioValue, setCurrentPortfolioValue] = useState<number>(0);
  const [annualReturnOnInvestment, setAnnualReturnOnInvestment] = useState<number>(10);
  const [withdrawalRate, setWithdrawalRate] = useState<number>(4);
  const [yearsToRetirement, setYearsToRetirement] = useState<number>(0);
  const [chartData, setChartData] = useState<any>({});
  const [endingPortfolioValue, setEndingPortfolioValue] = useState<number>(0);

  const calculateRetirement = () => {
    const data_points: any[] = [];
    const label_points: any[] = [];
    const data = {
      labels: label_points,
      datasets: [
        {
          label: 'Retirement Portfolio Balance',
          data: data_points,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    };

    let portfolioValue = currentPortfolioValue;
    let savingsRate = currentSavingsRate / 100;
    let annualExpenses = currentAnnualExpenses;
    let annualSavings = currentAnnualSavings;
    let annualIncome = currentAnnualIncome;
    let annualIncomeGrowth = currentAnnualIncomeGrowth / 100;
    let annualExpenseGrowth = currentAnnualExpenseGrowth / 100;
    let annualROI = annualReturnOnInvestment / 100;
    let withdrawalPercentage = withdrawalRate / 100;

    for (let i = 0; i <= 50; i++) {
      let interestEarned = 0;
      if (i !== 0) {
        interestEarned = portfolioValue * annualROI;
        portfolioValue += interestEarned + annualSavings;

        annualExpenses = annualExpenses * (1 + annualExpenseGrowth);
        annualSavings = annualIncome * savingsRate;
        annualIncome = annualIncome * (1 + annualIncomeGrowth);
      }
      data.labels.push(`Year ${i}`);
      data.datasets[0].data.push(parseFloat(portfolioValue.toFixed(2)));

      if (portfolioValue + interestEarned + annualSavings >= annualExpenses / withdrawalPercentage) {
        let remainder = annualExpenses / withdrawalPercentage - portfolioValue;
        let years = i + remainder / (annualSavings + interestEarned);
        setEndingPortfolioValue(portfolioValue + remainder);
        setYearsToRetirement(years);
        break;
      }
    }

    setChartData(data);
  };

  const handleCurrentAnnualIncomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualIncome = parseFloat(event.target.value);
    if (isNaN(currentAnnualIncome)) {
      setCurrentAnnualIncome(0);
      return;
    }
    let annualSavings =
      currentAnnualIncome - currentAnnualExpenses > 0 ? currentAnnualIncome - currentAnnualExpenses : 0;

    setCurrentAnnualIncome(currentAnnualIncome);
    setCurrentAnnualSavings(annualSavings);
    if (currentAnnualIncome < currentAnnualExpenses) {
      setCurrentAnnualExpenses(currentAnnualIncome);
    }

    setCurrentSavingsRate(Math.round((annualSavings / currentAnnualIncome) * 100));
  };

  const handleCurrentAnnualIncomeGrowthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualIncomeGrowth = parseFloat(event.target.value);
    if (isNaN(currentAnnualIncomeGrowth)) {
      setCurrentAnnualIncomeGrowth(0);
      return;
    }
    setCurrentAnnualIncomeGrowth(currentAnnualIncomeGrowth);
  };

  const handleCurrentAnnualSavingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualSavings = parseFloat(event.target.value);
    if (isNaN(currentAnnualSavings)) {
      setCurrentAnnualSavings(0);
      return;
    }
    setCurrentAnnualSavings(currentAnnualSavings);
    setCurrentAnnualExpenses(currentAnnualIncome - currentAnnualSavings);
    setCurrentSavingsRate((currentAnnualSavings / currentAnnualIncome) * 100);
  };

  const handleCurrentAnnualExpensesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentAnnualExpenses = parseFloat(event.target.value);
    if (isNaN(currentAnnualExpenses)) {
      setCurrentAnnualExpenses(0);
      return;
    }
    setCurrentAnnualExpenses(currentAnnualExpenses);
    setCurrentAnnualSavings(currentAnnualIncome - currentAnnualExpenses);
    setCurrentSavingsRate((currentAnnualSavings / currentAnnualIncome) * 100);
  };

  const handleAnnualExpensesGrowthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let annualExpenseGrowth = parseFloat(event.target.value);
    if (isNaN(annualExpenseGrowth)) {
      setCurrentAnnualExpenseGrowth(0);
      return;
    }
    setCurrentAnnualExpenseGrowth(annualExpenseGrowth);
  };
  const handleCurrentSavingsRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentSavingsRate = parseFloat(event.target.value);
    if (isNaN(currentSavingsRate)) {
      setCurrentSavingsRate(0);
      return;
    }
    let newAnnualSavings = Math.round((currentSavingsRate / 100) * currentAnnualIncome);
    setCurrentSavingsRate(currentSavingsRate);
    setCurrentAnnualSavings(newAnnualSavings);
    setCurrentAnnualExpenses(currentAnnualIncome - newAnnualSavings);
  };

  const handlePortfolioValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentPortfolioValue = parseFloat(event.target.value);
    if (isNaN(currentPortfolioValue)) {
      setCurrentPortfolioValue(0);
      return;
    }
    setCurrentPortfolioValue(currentPortfolioValue);
  };

  const handleAnnualReturnOnInvestmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let annualReturnOnInvestment = parseFloat(event.target.value);
    if (isNaN(annualReturnOnInvestment)) {
      setAnnualReturnOnInvestment(0);
      return;
    }
    setAnnualReturnOnInvestment(annualReturnOnInvestment);
  };

  const handleWithdrawalRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let withdrawalRate = parseFloat(event.target.value);
    if (isNaN(withdrawalRate)) {
      setWithdrawalRate(0);
      return;
    }
    setWithdrawalRate(withdrawalRate);
  };

  const convertToFormat = () => {};

  useEffect(() => {
    calculateRetirement();
    convertToFormat();
  }, [
    currentAnnualIncome,
    currentAnnualIncomeGrowth,
    currentAnnualExpenses,
    currentAnnualExpenseGrowth,
    currentAnnualSavings,
    currentPortfolioValue,
    currentSavingsRate,
    annualReturnOnInvestment,
    withdrawalRate,
    yearsToRetirement,
  ]);

  return (
        /**
     * Financial Independence, Retire Early Calculator
     * 
     * The Financial Independence, Retire Early (FIRE) Calculator is a powerful tool that helps you plan for early retirement and achieve financial independence. It allows you to estimate the number of years it will take for you to retire based on your current annual income, annual income growth, annual expenses, and other financial factors.
     * 
     * By inputting your financial information, such as your current annual income, expected income growth, annual expenses, and investment details, the calculator provides you with valuable insights into your retirement journey. It calculates your savings rate, annual savings, and withdrawal rate, taking into account factors like portfolio value and annual return on investment.
     * 
     * With the FIRE Calculator, you can make informed decisions about your finances and create a roadmap to achieve early retirement. It empowers you to optimize your savings, manage your expenses, and make strategic investment choices to reach your financial goals faster.
     * 
     * Start planning for your financial independence and early retirement today with the Financial Independence, Retire Early Calculator!
     */
    <Title label='Financial Independence, Retire Early Calculator' description='The Financial Independence, Retire Early (FIRE) Calculator
     is a powerful tool that helps you plan for early retirement and achieve financial independence. It allows you to estimate the number
      of years it will take for you to retire based on your current annual income, annual income growth, annual expenses, and other financial factors.
       This is one of the best ways that you can calculate your way to financial freedom on passive income!' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-4'>
          <br />
          <br />
          <LabeledInput
            label={'Annual Income'}
            desc={'The current annual income you earn'}
            type='number'
            value={currentAnnualIncome}
            format='USD'
            onChange={handleCurrentAnnualIncomeChange}
          />
          <LabeledInput
            label='Annual Income Growth'
            desc={'The expected annual income growth (salary increases)'}
            type='number'
            value={currentAnnualIncomeGrowth}
            format='%'
            onChange={handleCurrentAnnualIncomeGrowthChange}
          />
          <LabeledInput
            label='Annual Expenses'
            desc={'The amount of money that you spend every year'}
            type='number'
            value={currentAnnualExpenses}
            format='USD'
            onChange={handleCurrentAnnualExpensesChange}
          />
          <LabeledInput
            label='Annual Expense Growth'
            desc={'The expected annual expense growth (inflation)'}
            type='number'
            value={currentAnnualExpenseGrowth}
            format='%'
            onChange={handleAnnualExpensesGrowthChange}
          />
          <LabeledInput
            label='Annual Savings'
            desc={'The amount of money that you save every year'}
            type='number'
            value={currentAnnualSavings}
            format='USD'
            onChange={handleCurrentAnnualSavingsChange}
          />
          <LabeledInputRange
            label='Savings Rate'
            desc={'The percentage of your income you save each year'}
            type='range'
            min='0'
            max='100'
            value={currentSavingsRate}
            onChange={handleCurrentSavingsRateChange}
          />
          <div className='row'>
            <div className='col-lg-10'></div>
            <h5>{currentSavingsRate.toFixed()}%</h5>
          </div>
          <br />
          <LabeledInput
            label='Current Portfolio Value'
            desc={'The starting portfolio value that you have now'}
            type='number'
            value={currentPortfolioValue}
            format='USD'
            onChange={handlePortfolioValueChange}
          />
          <LabeledInput
            label='Annual Return on Investment'
            desc={'The expected annual return on your investments'}
            type='number'
            value={annualReturnOnInvestment}
            format='%'
            onChange={handleAnnualReturnOnInvestmentChange}
          />
          <LabeledInput
            label='Withdrawal Rate'
            desc={'The percentage of your portfolio you withdraw each year when you retire'}
            type='number'
            value={withdrawalRate}
            format='%'
            onChange={handleWithdrawalRateChange}
          />
        </div>

        <div className='col-lg-8'>
          {Object.keys(chartData).length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <div className='chart-size'>
                <Line
                  data={chartData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: (label: any) => `${formatUSD(label)}`,
                        },
                      },
                    },
                    elements: {
                      point: {
                        hitRadius: 25,
                      },
                    },
                    plugins: {
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function (context: any) {
                            const label = context.dataset.label;
                            const value = context.parsed.y;

                            const totalLabel = `${label}: ${formatUSD(value)}`;
                            return totalLabel;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
      <Title
        label={
          'With a savings rate of: ' +
          currentSavingsRate.toFixed(2) +
          '%, it will take ' +
          yearsToRetirement.toFixed(2) +
          ' years to retire \n with a portfolio value of: ' +
          formatUSD(endingPortfolioValue)
        }
      />
      <Table title={'Retirement Balance Yearly Breakdown'} data={chartData} />
    </Title>
  );
};

export default EarlyRetirementCalculator;
