import React, { useEffect, useState } from 'react';
import LabeledInput from '../../LabeledInput/LabeledInput';
import { Bar, Doughnut } from 'react-chartjs-2';
import Title from '../../Title/Title';
import Table from '../../Table/Table';

const AutoLoanCalculator = () => {
  const chartLabels = ['Interest', 'Principal', 'Insurance', 'Total'];
  const [loanAmount, setLoanAmount] = useState<number>(25000);
  const [interestRate, setInterestRate] = useState<number>(6.5);
  const [loanTerm, setLoanTerm] = useState<number>(5);
  const [monthlyPayment, setMonthlyPayment] = useState<number>(490);
  const [monthlyInsurance, setMonthlyInsurance] = useState<number>(100);

  // Calculated Monthly Payments data
  const [monthlyTotalPayments, setMonthlyTotalPayments] = useState<number[]>([]);
  const [monthlyPrincipalPayments, setMonthlyPrincipalPayments] = useState<number[]>([]);
  const [monthlyInterestPayments, setMonthlyInterestPayments] = useState<number[]>([]);
  const [monthlyInsurancePayments, setMonthlyInsurancePayments] = useState<number[]>([]);

  const handleLoanAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let loanAmount = parseFloat(event.target.value);
    if (isNaN(loanAmount)) {
      setLoanAmount(0);
      return;
    }

    if (loanAmount > 100000000) {
      setLoanAmount(100000000);
      return;
    } else {
      setLoanAmount(loanAmount);
    }
  };

  const handleInterestRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let interestRate = parseFloat(event.target.value);
    if (isNaN(interestRate)) {
      setInterestRate(0);
      return;
    }
    if (interestRate > 100) {
      setInterestRate(100);
      return;
    } else {
      setInterestRate(interestRate);
    }
  };

  const handleLoanTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let loanTerm = parseFloat(event.target.value);
    if (isNaN(loanTerm)) {
      setLoanTerm(0);
      return;
    }
    if (loanTerm > 50) {
      setLoanTerm(50);
      return;
    } else {
      setLoanTerm(loanTerm);
    }
  };

  const handleInsuranceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let insurance = parseFloat(event.target.value);
    if (isNaN(insurance)) {
      setMonthlyInsurance(0);
      return;
    }
    if (insurance > 10000) {
      setMonthlyInsurance(10000);
      return;
    } else {
      setMonthlyInsurance(insurance);
    }
  };

  const calculatePayment = (remainingBalance: number) => {
    const principal = loanAmount;
    const rate = interestRate / 100 / 12;
    const term = loanTerm * 12;
    //convert this to interest and principal payments
    const monthlyPayment = (principal * rate * Math.pow(1 + rate, term)) / (Math.pow(1 + rate, term) - 1);
    const monthlyInterestPayment = remainingBalance * rate;
    const monthlyPrincipalPayment = monthlyPayment - monthlyInterestPayment;

    //setMonthlyPayment(parseFloat(monthlyPayment.toFixed(2)));
    return [
      parseFloat(monthlyPayment.toFixed(2)),
      parseFloat(monthlyInterestPayment.toFixed(2)),
      parseFloat(monthlyPrincipalPayment.toFixed(2)),
    ];
  };

  const handleSubmit = () => {
    const monthlyTotalPayment = [];
    const monthlyPrincipalPayment = [];
    const monthlyInterestPayment = [];
    const monthlyInsurancePayment = [];

    let remainingBalance = loanAmount;

    // Iterate over every month
    for (let i = 0; i < loanTerm * 12; i++) {
      let paymentArray = calculatePayment(remainingBalance);
      remainingBalance = remainingBalance - paymentArray[2];

      monthlyTotalPayment.push(paymentArray[0]);
      monthlyInterestPayment.push(paymentArray[1]);
      monthlyPrincipalPayment.push(paymentArray[2]);
      monthlyInsurancePayment.push(monthlyInsurance);
    }

    setMonthlyTotalPayments(monthlyTotalPayment);
    setMonthlyPrincipalPayments(monthlyPrincipalPayment);
    setMonthlyInterestPayments(monthlyInterestPayment);
    setMonthlyInsurancePayments(monthlyInsurancePayment);
  };

  useEffect(() => {
    handleSubmit();
  }, [loanAmount, interestRate, loanTerm, monthlyPayment, monthlyInsurance]);

  // Graph data
  const monthly_data = {
    labels: monthlyTotalPayments.map((_, index) => `Month ${index + 1}`),
    datasets: [
      {
        label: chartLabels[0],
        data: monthlyInterestPayments,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[1],
        data: monthlyPrincipalPayments,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[2],
        data: monthlyInsurancePayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Graph data
  const monthly_data_table = {
    labels: monthlyTotalPayments.map((_, index) => `Month ${index + 1}`),
    datasets: [
      {
        label: chartLabels[0],
        data: monthlyInterestPayments,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[1],
        data: monthlyPrincipalPayments,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[2],
        data: monthlyInsurancePayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[3],
        data: monthlyTotalPayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  };
  const month_data = {
    labels: [chartLabels[0], chartLabels[1], chartLabels[2]],
    datasets: [
      {
        labels: [chartLabels[0], chartLabels[1], chartLabels[2]],
        data: [monthlyInterestPayments[0], monthlyPrincipalPayments[0], monthlyInsurancePayments[0]],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        hoverBorderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        hoverBackgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
      },
    ],
  };

  const monthly_options = {
    plugins: {
      title: {
        display: true,
        text: 'Monthly Payment ($)',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (value: any) {
            return (
              chartLabels[value.datasetIndex] +
              ': $' +
              value.formattedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            );
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (label: any) => `$${label}`,
        },
      },
    },
  };

  const doughnut_options = {
    plugins: {
      title: {
        display: true,
        text: 'Monthly Payment ($)',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (value: any) {
            return (
              value.label + ': $' + value.formattedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            );
          },
        },
      },
    },
    responsive: true,
  };

  return (
    <Title label='Auto Loan Calculator' description='Calculate your auto loan payments effortlessly with our user-friendly Auto Loan Calculator.
     Determine the loan amount, interest rate, and loan term to estimate your monthly payments accurately.
      Plan your vehicle financing wisely and make informed decisions.' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-5'>
          <br />
          <br />
          <LabeledInput
            label={'Loan Amount:'}
            desc={'The total loan amount that will be taken out for the vehicle'}
            type={'number'}
            value={loanAmount}
            format='USD'
            onChange={handleLoanAmountChange}
          />
          <LabeledInput
            label={'Interest Rate:'}
            desc={'The interest rate you expect to pay on the loan'}
            type={'number'}
            step={'0.01'}
            value={interestRate}
            format='%'
            onChange={handleInterestRateChange}
          />
          <LabeledInput
            label={'Loan Term (years):'}
            desc={'The number of years you will be paying off the loan'}
            type={'number'}
            value={loanTerm}
            onChange={handleLoanTermChange}
          />
          <LabeledInput
            label={'Insurance Payment:'}
            desc={'The amount you expect to pay for insurance each month'}
            type={'number'}
            format='USD'
            value={monthlyInsurance}
            onChange={handleInsuranceChange}
          />
          <button onClick={handleSubmit}>Calculate</button>
        </div>
        <div className='col-lg-7'>
          <div style={{ paddingBottom: '50px', paddingRight: '80px', float: 'right' }}>
            <Doughnut data={month_data} options={doughnut_options} />
            <br />
            <h5>Total Monthly Payment: ${monthlyTotalPayments[0]}</h5>
          </div>
        </div>
      </div>
      <div>
        <Bar data={monthly_data} options={monthly_options} />
      </div>
      <Table title={'Auto Loan Payment Breakdown'} data={monthly_data_table} />
    </Title>
  );
};

export default AutoLoanCalculator;
