import React, { useState, useEffect } from 'react';
import 'chart.js/auto';
import { Chart, Bar, Doughnut } from 'react-chartjs-2';
import Title from '../../Title/Title';
import Table from '../../Table/Table';

const MortgageCalculator = () => {
  const chartLabels = ['Interest', 'Principal', 'Property Taxes', 'Property Insurance', 'PMI', 'HOA Fees'];
  // Variables
  const [downPayment, setDownPayment] = useState(60000);
  const [propertyValue, setPropertyValue] = useState(300000);
  const [loanAmount, setLoanAmount] = useState(240000);
  const [interestRate, setInterestRate] = useState(5.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [propertyTaxes, setPropertyTaxes] = useState(4000);
  const [homeInsurance, setPropertyInsurance] = useState(1000);
  const [pmi, setPmi] = useState(0.22);
  const [hoaFees, setHoaFees] = useState(500);
  const [downPaymentSwitchValue, setDownPaymentSwitchValue] = useState(true);
  const [barChartSwitchValue, setBarChartSwitchValue] = useState(true);

  // Calculated Monthly Payments data
  const [monthlyTotalPayments, setMonthlyTotalPayments] = useState<number[]>([]);
  const [monthlyPrincipalPayments, setMonthlyPrincipalPayments] = useState<number[]>([]);
  const [monthlyInterestPayments, setMonthlyInterestPayments] = useState<number[]>([]);
  const [monthlyPropertyTaxesPayments, setMonthlyPropertyTaxesPayments] = useState<number[]>([]);
  const [monthlyPropertyInsurancePayments, setMonthlyPropertyInsurancePayments] = useState<number[]>([]);
  const [monthlyPmiPayments, setMonthlyPmiPayments] = useState<number[]>([]);
  const [monthlyHoaFeesPayments, setMonthlyHoaFeesPayments] = useState<number[]>([]);

  // Calculated Yearly Payments data
  const [yearlyTotalPayments, setYearlyTotalPayments] = useState<number[]>([]);
  const [yearlyPrincipalPayments, setYearlyPrincipalPayments] = useState<number[]>([]);
  const [yearlyInterestPayments, setYearlyInterestPayments] = useState<number[]>([]);
  const [yearlyPropertyTaxesPayments, setYearlyPropertyTaxesPayments] = useState<number[]>([]);
  const [yearlyPropertyInsurancePayments, setYearlyPropertyInsurancePayments] = useState<number[]>([]);
  const [yearlyPmiPayments, setYearlyPmiPayments] = useState<number[]>([]);
  const [yearlyHoaFeesPayments, setYearlyHoaFeesPayments] = useState<number[]>([]);

  const handleDownPaymentSwitchChange = (switchState: boolean) => {
    setDownPaymentSwitchValue(switchState);
    if (!switchState) {
      let percent = (downPayment / propertyValue) * 100;
      setDownPayment(percent);
      setLoanAmount(propertyValue - propertyValue * (percent / 100));
    } else {
      setDownPayment(propertyValue * (downPayment / 100));
      setLoanAmount(propertyValue - (downPayment / 100) * propertyValue);
    }
  };

  const handleBarChartSwitchValue = (switchState: boolean) => {
    setBarChartSwitchValue(switchState);
    handleSubmit();
  };

  const handleDownPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let downPayment = parseFloat(event.target.value);
    if (isNaN(downPayment)) {
      downPayment = 0;
      return;
    }

    if (downPayment > 200000000) {
      setDownPayment(200000000);
      return;
    } else {
      setDownPayment(downPayment);
    }

    if (!downPaymentSwitchValue) {
      setDownPayment(downPayment);
      setLoanAmount(propertyValue - propertyValue * (downPayment / 100));
    } else {
      setDownPayment(downPayment);
      setLoanAmount(propertyValue - downPayment);
    }
  };

  const handlePropertyValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyValue = parseFloat(event.target.value);
    if (isNaN(propertyValue)) {
      propertyValue = 0;
    }
    if (propertyValue > 200000000) {
      setPropertyValue(200000000);
      return;
    } else {
      setPropertyValue(propertyValue);
    }

    if (!downPaymentSwitchValue) {
      setDownPayment(downPayment);
      setLoanAmount(propertyValue - propertyValue * (downPayment / 100));
    } else {
      setDownPayment(downPayment);
      setLoanAmount(propertyValue - downPayment);
    }
  };

  const handleLoanAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let loanAmount = parseFloat(event.target.value);
    if (isNaN(loanAmount)) {
      setLoanAmount(0);
      return;
    }
    if (loanAmount > 200000000) {
      setLoanAmount(200000000);
      return;
    } else {
      setLoanAmount(loanAmount);
    }
  };

  const handleInterestRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let interestRate = parseFloat(event.target.value);
    if (isNaN(interestRate)) {
      setInterestRate(0);
      return;
    }
    if (interestRate > 100) {
      setInterestRate(100);
      return;
    } else {
      setInterestRate(interestRate);
    }
  };

  const handleLoanTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let loanTerm = parseFloat(event.target.value);
    if (isNaN(loanTerm)) {
      setLoanTerm(0);
      return;
    }
    if (loanTerm > 100) {
      setLoanTerm(100);
      return;
    } else {
      setLoanTerm(loanTerm);
    }
  };

  const handlePropertyTaxesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyTaxes = parseFloat(event.target.value);
    if (isNaN(propertyTaxes)) {
      setPropertyTaxes(0);
      return;
    }
    if (propertyTaxes > 200000) {
      setPropertyTaxes(200000);
      return;
    } else {
      setPropertyTaxes(propertyTaxes);
    }
  };

  const handlePropertyInsuranceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let homeInsurance = parseFloat(event.target.value);
    if (isNaN(homeInsurance)) {
      setPropertyInsurance(0);
      return;
    }
    if (homeInsurance > 200000) {
      setPropertyInsurance(200000);
      return;
    } else {
      setPropertyInsurance(homeInsurance);
    }
  };

  const handlePmiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let pmi = parseFloat(event.target.value);
    if (isNaN(pmi)) {
      setPmi(0);
      return;
    }
    if (pmi > 100) {
      setPmi(100);
      return;
    } else {
      setPmi(pmi);
    }
  };

  const handleHoaFeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let hoaFees = parseFloat(event.target.value);
    if (isNaN(hoaFees)) {
      setHoaFees(0);
      return;
    }
    if (hoaFees > 100000) {
      setHoaFees(100000);
      return;
    } else {
      setHoaFees(hoaFees);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [
    downPayment,
    propertyValue,
    loanAmount,
    interestRate,
    loanTerm,
    propertyTaxes,
    homeInsurance,
    pmi,
    hoaFees,
    barChartSwitchValue,
  ]); // Empty array means the effect only runs on mount

  const handleSubmit = () => {
    // Monthly Payments
    const monthlyTotalPayment = [];
    const monthlyPrincipalPayment = [];
    const monthlyInterestPayment = [];
    const monthlyPropertyTaxes = [];
    const monthlyPropertyInsurance = [];
    const monthlyPmi = [];
    const monthlyHoaFees = [];

    // Yearly Payments
    const yearlyTotalPayment = [];
    const yearlyPrincipalPayment = [];
    const yearlyInterestPayment = [];
    const yearlyPropertyTaxes = [];
    const yearlyPropertyInsurance = [];
    const yearlyPmi = [];
    const yearlyHoaFees = [];

    let yearlyTotalPaymentAdder = 0;
    let yearlyPrincipalPaymentAdder = 0;
    let yearlyInterestPaymentAdder = 0;
    let yearlyPropertyTaxesAdder = 0;
    let yearlyPropertyInsuranceAdder = 0;
    let yearlyPmiAdder = 0;
    let yearlyHoaFeesAdder = 0;

    let remainingBalance = loanAmount;
    let monthlyTotalPaymentAdder = 0;

    // Iterate over every month
    for (let i = 0; i < loanTerm * 12; i++) {
      let paymentArray = calculatePayment(remainingBalance);
      remainingBalance = remainingBalance - paymentArray[0];
      monthlyTotalPaymentAdder = parseFloat(
        (
          paymentArray[0] +
          paymentArray[1] +
          paymentArray[2] +
          paymentArray[3] +
          paymentArray[4] +
          paymentArray[5]
        ).toFixed(2)
      );
      monthlyTotalPayment.push(monthlyTotalPaymentAdder);
      yearlyTotalPaymentAdder +=
        paymentArray[0] + paymentArray[1] + paymentArray[2] + paymentArray[3] + paymentArray[4] + paymentArray[5];
      monthlyPrincipalPayment.push(paymentArray[0]);
      yearlyPrincipalPaymentAdder += paymentArray[0];
      monthlyInterestPayment.push(paymentArray[1]);
      yearlyInterestPaymentAdder += paymentArray[1];
      monthlyPropertyTaxes.push(paymentArray[2]);
      yearlyPropertyTaxesAdder += paymentArray[2];
      monthlyPropertyInsurance.push(paymentArray[3]);
      yearlyPropertyInsuranceAdder += paymentArray[3];
      monthlyPmi.push(paymentArray[4]);
      yearlyPmiAdder += paymentArray[4];
      monthlyHoaFees.push(paymentArray[5]);
      yearlyHoaFeesAdder += paymentArray[5];

      if ((i + 1) % 12 == 0) {
        yearlyTotalPayment.push(yearlyTotalPaymentAdder);
        yearlyPrincipalPayment.push(yearlyPrincipalPaymentAdder);
        yearlyInterestPayment.push(yearlyInterestPaymentAdder);
        yearlyPropertyTaxes.push(yearlyPropertyTaxesAdder);
        yearlyPropertyInsurance.push(yearlyPropertyInsuranceAdder);
        yearlyPmi.push(yearlyPmiAdder);
        yearlyHoaFees.push(yearlyHoaFeesAdder);

        yearlyTotalPaymentAdder = 0;
        yearlyPrincipalPaymentAdder = 0;
        yearlyInterestPaymentAdder = 0;
        yearlyPropertyTaxesAdder = 0;
        yearlyPropertyInsuranceAdder = 0;
        yearlyPmiAdder = 0;
        yearlyHoaFeesAdder = 0;
      }
    }

    // Set all the respective payment sections
    // Monthly
    setMonthlyTotalPayments(monthlyTotalPayment);
    setMonthlyPrincipalPayments(monthlyPrincipalPayment);
    setMonthlyInterestPayments(monthlyInterestPayment);
    setMonthlyPropertyTaxesPayments(monthlyPropertyTaxes);
    setMonthlyPropertyInsurancePayments(monthlyPropertyInsurance);
    setMonthlyPmiPayments(monthlyPmi);
    setMonthlyHoaFeesPayments(monthlyHoaFees);

    // Yearly
    setYearlyTotalPayments(yearlyTotalPayment);
    setYearlyPrincipalPayments(yearlyPrincipalPayment);
    setYearlyInterestPayments(yearlyInterestPayment);
    setYearlyPropertyTaxesPayments(yearlyPropertyTaxes);
    setYearlyPropertyInsurancePayments(yearlyPropertyInsurance);
    setYearlyPmiPayments(yearlyPmi);
    setYearlyHoaFeesPayments(yearlyHoaFees);
  };

  // Calculate the monthly mortgage payment
  const calculatePayment = (remainingBalance: number) => {
    const r = interestRate / 12 / 100;
    const n = loanTerm * 12;
    const monthlyPayment = (loanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    const monthlyInterestPayment = remainingBalance * r;
    const monthlyPrincipalPayment = monthlyPayment - monthlyInterestPayment;
    const monthlyPropertyTaxes = propertyTaxes / 12;
    const monthlyPropertyInsurance = homeInsurance / 12;
    const monthlyPmi = (pmi / 12 / 100) * propertyValue;
    const monthlyHoaFees = hoaFees / 12;

    return [
      parseFloat(monthlyPrincipalPayment.toFixed(2)),
      parseFloat(monthlyInterestPayment.toFixed(2)),
      parseFloat(monthlyPropertyTaxes.toFixed(2)),
      parseFloat(monthlyPropertyInsurance.toFixed(2)),
      parseFloat(monthlyPmi.toFixed(2)),
      parseFloat(monthlyHoaFees.toFixed(2)),
    ];
  };

  const month_data = {
    labels: chartLabels,
    datasets: [
      {
        labels: chartLabels,
        data: [
          monthlyInterestPayments[0],
          monthlyPrincipalPayments[0],
          monthlyPropertyTaxesPayments[0],
          monthlyPropertyInsurancePayments[0],
          monthlyPmiPayments[0],
          monthlyHoaFeesPayments[0],
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        hoverBorderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        hoverBackgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
      },
    ],
  };

  // Graph data
  const monthly_data = {
    labels: monthlyTotalPayments.map((_, index) => `Month ${index + 1}`),
    datasets: [
      {
        label: chartLabels[0],
        data: monthlyInterestPayments,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[1],
        data: monthlyPrincipalPayments,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[2],
        data: monthlyPropertyTaxesPayments,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[3],
        data: monthlyPropertyInsurancePayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[4],
        data: monthlyPmiPayments,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[5],
        data: monthlyHoaFeesPayments,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Graph data
  const monthly_data_table = {
    labels: monthlyTotalPayments.map((_, index) => `Month ${index + 1}`),
    datasets: [
      {
        label: chartLabels[0],
        data: monthlyInterestPayments,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[1],
        data: monthlyPrincipalPayments,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[2],
        data: monthlyPropertyTaxesPayments,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[3],
        data: monthlyPropertyInsurancePayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[4],
        data: monthlyPmiPayments,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[5],
        data: monthlyHoaFeesPayments,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
      {
        label: 'Total',
        data: monthlyTotalPayments,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Graph data
  const yearly_data = {
    labels: yearlyTotalPayments.map((_, index) => `Year ${index + 1}`),
    datasets: [
      {
        label: chartLabels[0],
        data: yearlyInterestPayments,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[1],
        data: yearlyPrincipalPayments,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[2],
        data: yearlyPropertyTaxesPayments,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[3],
        data: yearlyPropertyInsurancePayments,
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[4],
        data: yearlyPmiPayments,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: chartLabels[5],
        data: yearlyHoaFeesPayments,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  const yearly_options = {
    plugins: {
      title: {
        display: true,
        text: 'Yearly Payment ($)',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (value: any) {
            return (
              chartLabels[value.datasetIndex] +
              ': $' +
              value.formattedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            );
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (label: any) => `$${label}`,
        },
      },
    },
  };

  const monthly_options = {
    plugins: {
      title: {
        display: true,
        text: 'Monthly Payment ($)',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (value: any) {
            return (
              chartLabels[value.datasetIndex] +
              ': $' +
              value.formattedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            );
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (label: any) => `$${label}`,
        },
      },
    },
  };

  const doughnut_options = {
    plugins: {
      title: {
        display: true,
        text: 'Monthly Payment ($)',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (value: any) {
            return (
              value.label + ': $' + value.formattedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            );
          },
        },
      },
    },
    responsive: true,
  };

  return (
    <Title label='Mortgage Calculator' description='Calculate your mortgage payments accurately and efficiently with our Mortgage Calculator.
     Determine your monthly payment amount based on the property value and down payment. Our user-friendly interface allows you to input the
      necessary details and instantly obtain the estimated monthly payment. Make informed decisions about your mortgage by utilizing our powerful Mortgage Calculator tool.' additionalMetaTags={true}>
      <div className='row'>
        <div className='col-lg-7'>
          <h3>Property Details</h3>
          <br />
          <div>
            <label htmlFor='propertyValue' style={{ marginLeft: 5, marginRight: 5 }}>
              Property Value:
            </label>
            <input type='text' id='propertyValue' value={propertyValue} onChange={handlePropertyValueChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>$</label>
          </div>
          <div className='row'>
            <div>
              <label htmlFor='downPayment' style={{ marginLeft: 5, marginRight: 5 }}>
                Down Payment:
              </label>
              <input type='text' id='downPayment' value={downPayment} onChange={handleDownPaymentChange} />
              {downPaymentSwitchValue === true ? (
                <label style={{ marginLeft: 5, marginRight: 5 }}>$</label>
              ) : (
                <label style={{ marginLeft: 5, marginRight: 5 }}>%</label>
              )}
            </div>
            <div className='row col-md-5'>
              <div className='col-md-3'>
                <div style={{ textAlign: 'left', marginRight: 5 }}>
                  <input
                    type='radio'
                    checked={downPaymentSwitchValue}
                    onChange={() => handleDownPaymentSwitchChange(!downPaymentSwitchValue)}
                    style={{ marginRight: 5 }}
                  />
                  <label>{'$'}</label>
                </div>
              </div>
              <div className='col-md-3'>
                <div style={{ textAlign: 'left' }}>
                  <input
                    type='radio'
                    checked={!downPaymentSwitchValue}
                    onChange={() => handleDownPaymentSwitchChange(!downPaymentSwitchValue)}
                    style={{ marginRight: 5 }}
                  />
                  <label>{'%'}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor='loanAmount' style={{ marginLeft: 5, marginRight: 5 }}>
              Loan Amount:
            </label>
            <input type='text' id='loanAmount' value={loanAmount} onChange={handleLoanAmountChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>$</label>
          </div>
          <div>
            <label htmlFor='interestRate' style={{ marginLeft: 5, marginRight: 5 }}>
              Interest Rate:
            </label>
            <input
              type='number'
              id='interestRate'
              step='0.01'
              value={interestRate}
              onChange={handleInterestRateChange}
            />
            <label style={{ marginLeft: 5, marginRight: 5 }}>%</label>
          </div>
          <div>
            <label htmlFor='loanTerm' style={{ marginLeft: 5, marginRight: 5 }}>
              Loan Term:
            </label>
            <input type='text' id='loanTerm' value={loanTerm} onChange={handleLoanTermChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>years</label>
          </div>
          <div>
            <label htmlFor='propertyTaxes' style={{ marginLeft: 5, marginRight: 5 }}>
              Property Taxes:
            </label>
            <input type='text' id='propertyTaxes' value={propertyTaxes} onChange={handlePropertyTaxesChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>$/yr</label>
          </div>
          <div>
            <label htmlFor='homeInsurance' style={{ marginLeft: 5, marginRight: 5 }}>
              Property Owner's Insurance:
            </label>
            <input type='text' id='homeInsurance' value={homeInsurance} onChange={handlePropertyInsuranceChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>$/yr</label>
          </div>
          <div>
            <label htmlFor='pmi' style={{ marginLeft: 5, marginRight: 5 }}>
              PMI:
            </label>
            <input type='text' id='pmi' value={pmi} onChange={handlePmiChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>%</label>
          </div>
          <div>
            <label htmlFor='hoaFees' style={{ marginLeft: 5, marginRight: 5 }}>
              HOA Fees:
            </label>
            <input type='text' id='hoaFees' value={hoaFees} onChange={handleHoaFeesChange} />
            <label style={{ marginLeft: 5, marginRight: 5 }}>$/yr</label>
          </div>
          <button onClick={handleSubmit} style={{ marginLeft: 5, marginRight: 5 }}>
            Calculate
          </button>
          <br />
          <br />
          <h5>Bar Chart</h5>
          <div className='row col-md-5'>
            <div className='col-md-3'>
              <div style={{ textAlign: 'left', marginRight: 5 }}>
                <input
                  type='radio'
                  checked={barChartSwitchValue}
                  onChange={() => handleBarChartSwitchValue(!barChartSwitchValue)}
                  style={{ marginRight: 5 }}
                />
                <label>{'Yearly'}</label>
              </div>
            </div>
            <div className='col-md-3'>
              <div style={{ textAlign: 'left' }}>
                <input
                  type='radio'
                  checked={!barChartSwitchValue}
                  onChange={() => handleBarChartSwitchValue(!barChartSwitchValue)}
                  style={{ marginRight: 5 }}
                />
                <label>{'Monthly'}</label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-5'>
          <Doughnut data={month_data} options={doughnut_options} style={{ width: '50%' }} />
          <br />
          <h5>Total Monthly Payment: ${monthlyTotalPayments[0]}</h5>
        </div>
      </div>
      <br />
      <br />
      <div>
        {barChartSwitchValue === true ? (
          <Bar data={yearly_data} options={yearly_options} />
        ) : (
          <Bar data={monthly_data} options={monthly_options} />
        )}
      </div>
      <Table title={'Property Payment Breakdown'} data={monthly_data_table} />
    </Title>
  );
};

// TODO: Add defitions for each detail, then link out a "Learn more" intra site or extra site
export default MortgageCalculator;
